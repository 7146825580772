import React from "react";
import {Switch, Route, NavLink, useRouteMatch} from "react-router-dom";
import PointOfInterest from "./PointOfInterest";
import Courses from "./Courses";
import Games from "./Games/index";
import Pages from "./Pages";

const MenuContents = () => {
    let {path, url} = useRouteMatch();
    return (
        <div className={'row'}>
            <ul className={"nav nav-tabs col-xl-6 col-lg-8 col-md-10 justify-content-around mt-2 mb-2"}>
                <li className={"nav-item"}>
                    <NavLink to={`${path}`} className={"nav-link"}
                             isActive={(match, location) => {
                                 return location.pathname === url;
                             }}>
                        Les Parcours
                    </NavLink>
                </li>
                <li className={"nav-item"}>
                    <NavLink to={`${path}/points-of-interest`} className={"nav-link"} activeClassName={'active'}>
                        Les POIS
                    </NavLink>
                </li>
                <li className={"nav-item"}>
                    <NavLink to={`${path}/games`} className={"nav-link"} activeClassName={'active'}>
                        Les Jeux
                    </NavLink>
                </li>
                <li className={"nav-item"}>
                    <NavLink to={`${path}/pages`} className={"nav-link"} activeClassName={'active'}>
                        Les Pages
                    </NavLink>
                </li>
            </ul>
            <div className={'col-12'}>
                <Switch>
                    <Route exact path={`${path}`} component={Courses}/>
                    {/*<Route path={`${path}/course`} component={Courses}/>*/}
                    <Route path={`${path}/points-of-interest`} component={PointOfInterest}/>
                    <Route path={`${path}/games`} component={Games}/>
                    <Route path={`${path}/pages`} component={Pages}/>
                </Switch>
            </div>
        </div>
    )
};

export default MenuContents
