import React from "react";
import {Editor} from '@tinymce/tinymce-react';
import Fetch from "../util/Fetch";
import {API_URL, EDITOR_KEY} from '../bin/config' ;

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class EditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: props.page,
      editingTitle: false
    };
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.closeEditionTitle = this.closeEditionTitle.bind(this)
  }

  // Upload image handler for puzzle background and elements image.
  handleUpload(event) {
    event.persist();
    const formData = new FormData();
    // console.log(event.target.files);
    let file = event.target.files[0];
    formData.append('image', file);
    Fetch.create('/api/upload/image', formData, true).then(res => {
      // console.log(res)
      if (res.result)
        this.setState({
          page: {
            ...this.state.page,
            featured: res.response.imageUrl
          }
        })
    });
  }

  handleEditorChange(content) {
    this.setState({
      page: {
        ...this.state.page,
        description: content
      }
    })
  };

  // Async function edit page into database.
  async handleSave(event) {
    event.preventDefault();
    // @todo if this.state.page._id => update || create
    const puzzleUpdated = await Fetch.update('/api/pages/' + this.state.page._id, this.state.page);
    if (puzzleUpdated.result) {
      this.props.back()
    }
  }

  onChangeInput(evt) {
    evt.persist();
    this.setState(state => ({page: {...state.page, title: evt.target.value}}))
  }

  onChange(evt) {
    evt.persist();
    this.setState(state => ({page: {...state.page, [evt.target.name]: evt.target.value}}))
  }

  closeEditionTitle() {
    this.setState({editingTitle: false})
  }

  render() {
    return (
      <div className={'row'}>
        <div className={'mb-3 ml-3'}>
          <button onClick={this.props.back} className={'btn'}><FontAwesomeIcon icon={'chevron-left'}/> Retour</button>
        </div>
        {
          this.state.editingTitle || !this.state.page.title ? (
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="Titre de la page" value={this.state.page.title}
                     aria-label="Titre de la page" aria-describedby="button-addon2"
                     onBlur={this.closeEditionTitle} onChange={this.onChangeInput}/>
              <div className="input-group-append">
                <button className="btn btn-outline" type="button" id="button-addon2"
                        onClick={this.closeEditionTitle}>Valider
                </button>
              </div>
            </div>
          ) : (<h1 className={'col-sm-12 editable'} style={{cursor: 'pointer'}}
                   onClick={() => this.setState({editingTitle: true})}>{this.state.page.title}
            <FontAwesomeIcon icon={'edit'} style={{
              fontSize: '.5em',
              position: 'absolute',
              top: '.5em',
              marginLeft: '10px',
              color: 'orange',
            }}/></h1>)
        }
        {
          (this.props.structure && this.props.structure.type === 'desktop') ?
            <div className={"mb-5 col-12"}>
              <div className={"row"}>
                <div className="form-group col-6">
                  <label htmlFor="name">Nom</label>
                  <input type="text" className="form-control" id="name" aria-describedby="nameHelp" name={"name"}
                         value={this.state.page.name || ''} onChange={this.onChange.bind(this)}/>
                  <small id="nameHelp" className="form-text text-muted">Nom utilisé pour le lien dans les menus de
                    navigation.</small>
                </div>
                <div className="form-group col-6">
                  <label htmlFor="slug">Nom</label>
                  <input type="text" className="form-control" id="slug" aria-describedby="slugHelp" name={"slug"} disabled={this.state.page.slug === '/'}
                         value={this.state.page.slug || ''} onChange={this.onChange.bind(this)} required={true} pattern={'\/[a-z06-_]*'}/>
                  <small id="slugHelp" className="form-text text-muted">Utilisé comme url de la page. Doit commencer par / puis être une suite de carateres minuscules, chiffres et tirets (-).</small>
                </div>
              </div>
            </div> : null
        }
        {
          (!this.props.structure.noFeature) ?
            <label className={"file-image-label col-12 mt-3 mb-5"}>
              <img style={{objectFit: 'cover', width: '100%', minWidth: '100%', padding: 0, height: '400px'}}
                   src={this.state.page.featured ? ((/https?:\/\//gi.test(this.state.page.featured) ? '' : API_URL) + this.state.page.featured) : '/static/default-page.png'}
                   alt={'Image de la page ' + this.state.page.title}/>
              <input id={"facebook"} type={"file"} onChange={this.handleUpload}
                     accept="image/png,image/jpg,image/jpeg,image/gif"/>
            </label> : <p className={"text-center mb-5 col-12"}>Aucune image n'est utilisée sur cette page</p>
        }
        <div className={"input-group mb-5 col-12"}>
          <label htmlFor={"pageDescription"} className={"col-2 col-form-label"}>Description de la page&nbsp;:</label>
          <div style={{height: "300px"}} className={"col-sm-10"}>
            <Editor
              apiKey={EDITOR_KEY}
              value={this.state.page.description ? this.state.page.description : ''}
              init={{
                height: 300,
                menubar: true,
                plugins: [
                  'advlist autolink lists link image',
                  'charmap print preview anchor help',
                  'searchreplace visualblocks code',
                  'insertdatetime media table paste wordcount'
                ],
                toolbar:
                  `undo redo | formatselect | bold italic |
                                        alignleft aligncenter alignright |
                                        bullist numlist outdent indent | help`,
                entity_encoding: "raw",
              }}
              onEditorChange={this.handleEditorChange}
            />
          </div>
        </div>
        <div className={'text-right col-12'}>
          <button type={"button"} className={"btn btn-save"} onClick={(e) => this.handleSave(e)}>
            Enregister
          </button>
        </div>
      </div>
    )
  }
}

export default EditPage
