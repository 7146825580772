import React from "react";
import Fetch from "../util/Fetch";
import '../stylesheets/EditPOI.css';
import Buttons from "./UploadFiles/Buttons";
import {Editor} from '@tinymce/tinymce-react';
import '../stylesheets/HorizontalScrolling.scss'
import {faVideo} from "@fortawesome/free-solid-svg-icons";
import {faCaretUp} from "@fortawesome/free-solid-svg-icons";
import {faVolumeUp} from "@fortawesome/free-solid-svg-icons";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVrCardboard} from "@fortawesome/free-solid-svg-icons";
import {faCameraRetro} from "@fortawesome/free-solid-svg-icons";
import SwitchRadio from "./SwitchRadio";
import {EDITOR_KEY, API_URL} from '../bin/config' ;


class EditPOI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCourse: props.courses[0],
    };

    this.changeCourse = this.changeCourse.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSavePoi = this.handleSavePoi.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  handleChange(event) {
    let value = event.target.value;
    // console.log(event.target)
    // console.log(event.target.type)
    if (event.target.id === 'textEditor') {
      value = event.target.getContent()
    } else if (event.target.type === "number") {
      value = parseFloat(value)
    }
    // console.log({[event.target.name]: value})
    this.props.update({...this.props.poi, [event.target.name]: value})

    // this.setState({
    //     poi: {...this.state.poi, [event.target.name]: value}
    // })
  }

  changeCourse(event) {
    let currentCourse = this.props.courses.find(c => c._id === event.target.value);
    this.setState({currentCourse})
  }

  handleUpload(event) {
    let formData = new FormData();
    let file = event.target.files[0];
    let buttonName = event.currentTarget.name;
    document.getElementById(event.target.id).value = '';


    let poi = {...this.props.poi};
    if (typeof poi.course !== 'object' || poi.course === null)
      poi.course = {};
    if (!poi.course[this.state.currentCourse._id])
      poi.course[this.state.currentCourse._id] = {};
    if (!poi.course[this.state.currentCourse._id].medias)
      poi.course[this.state.currentCourse._id].medias = [];

    if (event.target.name === 'image' || event.target.name === 'img360') {
      //Create object image for send to server
      formData.append('image', file);
      Fetch.upload('/api/upload/image', formData).then(resp => {
        if (resp.result) {
          poi.course[this.state.currentCourse._id].medias.push({type: buttonName, source: resp.response.imageUrl});
          this.props.update(poi);
        } else {
          // console.log('error');
          // console.log(resp)
        }
      });
    } else if (event.target.name === 'video') {
      formData.append('video', file);
      Fetch.upload('/api/upload/video', formData).then(resp => {
        if (resp.result) {
          poi.course[this.state.currentCourse._id].medias.push({type: buttonName, source: resp.response.video});
          this.props.update(poi);
        } else {
          // console.log('error');
          // console.log(resp)
        }
      });

    } else if (event.target.name === 'audio') {
      formData.append('audio', file);
      Fetch.upload('/api/upload/' + buttonName, formData).then(resp => {
        if (resp.result) {
          poi.course[this.state.currentCourse._id].audio = resp.response.audioUrl;
          this.props.update(poi);
        } else {
          // console.log('error');
          // console.log(resp)
        }
      });
    }
  }

  handleSavePoi() {
    this.props.validate(this.props.poi)
  }

  rmMedia(index) {
    console.log('rmMedia')
    let poi = {...this.props.poi};
    let medias = [];
    for (let i in poi.course[this.state.currentCourse._id].medias) {
      console.log(i, index)
      if (i != index)
        medias.push(poi.course[this.state.currentCourse._id].medias[i])
    }
    console.log(medias)
    poi.course[this.state.currentCourse._id].medias = medias;
    this.props.update(poi)
  }

  changeMediaDescription(event, index) {
    let poi = {...this.props.poi};
    poi.course[this.state.currentCourse._id].medias[index].description = event.target.value;
    this.props.update(poi)
  }

  moveMedia(index, up=true) {
    let poi = {...this.props.poi};
    let element = poi.course[this.state.currentCourse._id].medias[index];
    poi.course[this.state.currentCourse._id].medias.splice(index, 1);
    poi.course[this.state.currentCourse._id].medias.splice(up ? index-1 : index+1, 0, element);
    this.props.update(poi)
  }

  handleEditorChange(content) {
    let poi = {...this.props.poi};
    if (!poi.course || typeof poi.course !== 'object')
      poi.course = {};
    if (!poi.course[this.state.currentCourse._id])
      poi.course[this.state.currentCourse._id] = {}
    poi.course[this.state.currentCourse._id].description = content;
    // console.log(poi)
    this.props.update(poi)
  }

  render() {
    let poi = {
      _id: '',
      number: '',
      floor: '',
      x: 10,
      y: 10,
      title: '',
      // description: '',
      course: {},
      datas: {
        medias: [],
        audio: ''
      },
      uid: '',
      QRCode: '',
      ...this.props.poi
    };

    let {x, y} = {x: parseFloat(poi.x), y: parseFloat(poi.y)};
    if (x <= 0) x = 1;
    if (x >= 100) x = 99;
    if (y <= 0) y = 1;
    if (y >= 100) y = 99;

    return (
      <div className={"p-0 col-sm-12"}>
        <div className={''}>
          <h3 className={"text-center"}>Point d'intérêt numéro {poi.number}</h3>
          <button className={'btn'} style={{position: 'absolute', top: 0}} onClick={this.props.cancel}><FontAwesomeIcon
            icon={'chevron-left'}/> Retour
          </button>
        </div>
        <div className={"card-body frame m-0 w-100"} style={{maxWidth: '100%'}}>
          <div className={"input-group no-gutters mb-4"}>
            <label htmlFor={"poiTitle"} className={"col-sm-2 col-form-label"}>Titre&nbsp;:</label>
            <div className={"col-sm-10"}>
              <input type={"text"} className={"form-control"} id={"poiTitle"} name={'title'}
                     value={poi.title} onChange={this.handleChange}/>
            </div>
          </div>
          <div className={"input-group no-gutters mb-4"}>
            <label htmlFor={"poiNumber"} className={"col-sm-2 col-form-label"}>Numéro du POI&nbsp;:</label>
            <div className={"col-sm-10"}>
              <input type={"text"} className={"form-control"} id={"poiNumber"} name={'number'}
                     value={poi.number} onChange={this.handleChange}/>
            </div>
          </div>
          <div className={"collapse row"} id={"collapseExample"}>
            <div className={"input-group mb-3"}>
              <label htmlFor={"poiPosition"} className={"col-sm-2 col-form-label"}>Position&nbsp;:</label>
              <div className={"col-sm-3"}>
                <input type={"number"} className={"form-control"} id={"poiPositionX"} name={"x"}
                       value={x} onChange={this.handleChange}/>
              </div>
              <div className={"col-sm-3"}>
                <input type={'number'} className={"form-control"} id={"poiPositionY"} name={'y'}
                       value={y} onChange={this.handleChange}/>
              </div>
              <div className={"col-sm-4"}>
                <SwitchRadio elements={this.props.plans.sort((a, b) => a.level < b.level ? -1 : 1)}
                             selectedValue={this.props.currentFloor._id}
                             handleToggle={this.toggleSwitch} disabled={true}
                             style={{
                               scale: '.7',
                               display: 'flex',
                               width: '140%',
                               marginLeft: '-53px',
                               marginTop: '-7px'
                             }}/>
              </div>
            </div>
            <div className={"input-group mb-3"}>
              <label htmlFor={"qrCode"} className={"col-sm-2 col-form-label"}>QR Code&nbsp;:</label>
              <div className={"col-sm-3"}>
                <input type={"text"} className={"form-control"} id={"qrCode"} name={'QRCode'}
                       value={poi.QRCode} onChange={this.handleChange} readOnly={!!poi.QRCode}/>
              </div>
              <label htmlFor={"uniqId"} className={"col-sm-2 col-form-label text-center"}>Unique Id&nbsp;:</label>
              <div className={"col-sm-5"}>
                <input type={"text"} className={"form-control"} id={"uniqId"} name={'uid'}
                       value={poi.uid} onChange={this.handleChange} readOnly={!!poi.uid}/>
              </div>
            </div>

          </div>
          <span className={"center-btn mb-3 pointer collapseButton"} role={"button"} onClick={() => {
            document.getElementById('collapseExample').classList.toggle('show')
          }}>
                            Positionnement et identification&nbsp;&nbsp;
            <FontAwesomeIcon className={'faCaretUp'} icon={faCaretUp}/>
                        <FontAwesomeIcon className={'faCaretDown'} icon={faCaretDown}/>
                    </span>
          <div className={"btn_group mt-3 no-gutters"}>
            <label className={"align-btn-group col-2"}>Parcours&nbsp;:</label>
            <div className={"col-10 btn_group_style justify-content-around"}>
              {
                this.props.courses && this.props.courses.length ? this.props.courses.map(c => {
                  return (
                    <button
                      className={"btn_single " + (this.state.currentCourse._id === c._id ? "active_slide" : "")}
                      value={c._id} name={c._id} onClick={this.changeCourse} key={c._id}>{c.title}</button>
                  );
                }) : null
              }
            </div>
          </div>
          <div className={"form-group row my-5"}>
            <label htmlFor={"gallery"} className={"align-btn-group col-2"}>Contenu multimedia&nbsp;:</label>
                  <div className="row d-block" id={"gallery"} style={{position:"relative"}}>
                    {poi && poi.course && poi.course[this.state.currentCourse._id] && poi.course[this.state.currentCourse._id].medias && !poi.course[this.state.currentCourse._id].medias.includes(null) ?
                      poi.course[this.state.currentCourse._id].medias.map(media => {
                        let index = poi.course[this.state.currentCourse._id].medias.findIndex(i => i === media);
                        return (
                              <div>
                                <div
                                  className={'p-2 row '}
                                  style={{display: 'flex', flexDirection: "row"}}
                                >

                                  <div style={{position: "relative"}} className={'col-4'}>
                                    {
                                      media.type === 'video' ? (
                                        <video style={{}} controls={true} resizeMode={'cover'} fullscreen={false}
                                               paused={true}
                                               className="img-thumbnail">
                                          <source
                                            src={(/https?:\/\//.test(media.source) ? '' : API_URL) + media.source}/>
                                        </video>
                                      ) : (<img key={index.toString()}
                                                src={(/https?:\/\//.test(media.source) ? '' : API_URL) + media.source}
                                                className="img-thumbnail" alt=""/>)
                                    }
                                    <FontAwesomeIcon
                                      icon={media.type === 'video' ? 'play-circle' : (media.type === 'img360' ? 'vr-cardboard' : 'image')}
                                      size={'3x'} color={'rgb(255,255,255,0.5)'}/>
                                  </div>
                                  <div className={'col-4 d-flex flex-column justify-content-center align-items-center'}>
                                    <label className={'d-flex'}>Description</label>
                                    <input type={'text'} value={media.description}
                                           onChange={(e) => this.changeMediaDescription(e, index)}/>
                                  </div>
                                  <div className={'col-4 d-flex flex-column justify-content-center align-items-center'}>
                                    <div className={'d-flex flex-rowjustify-content-center '}>
                                      {index !== 0 &&
                                        <span className={"btn btn-link d-flex justify-content-center align-items-center"}
                                        onClick={() => this.moveMedia(index)}>
                                          <FontAwesomeIcon icon="arrow-up"/></span>}
                                      {index !== (poi.course[this.state.currentCourse._id].medias.length - 1) &&
                                        <span className={"btn btn-link d-flex justify-content-center align-items-center"}
                                              onClick={() => this.moveMedia(index, false)}>
                                          <FontAwesomeIcon icon="arrow-down"/></span>}
                                    </div>
                                    <span className={"btn btn-link d-flex justify-content-center align-items-center"}
                                          onClick={() => {
                                            if (window.confirm('Voulez vous supprimer ce média ?')) this.rmMedia(index)
                                          }}>
                                      <FontAwesomeIcon icon="trash-alt" className={'mr-3'}/> Supprimer</span>
                                  </div>
                                </div>
                              </div>
                        )
                      }) : null
                    }
                  </div>
          </div>
          <div className={"row "}>
            <div className={'col-md-3 offset-md-3'}>
              <Buttons isVisible={true} isSingle={true} name={'video'}
                       onChange={(event) => this.handleUpload(event)}
                       icon={faVideo} label={'Importer une vidéo'} accept={"video/*"}/>
            </div>
            <div className={'col-md-3'}>
              <Buttons isVisible={true} isSingle={true} name={'image'}
                       onChange={(event) => this.handleUpload(event)}
                       icon={faCameraRetro} label={'Importer une image'} accept={".png, .jpg, .jpeg, .gif"}/>
            </div>
            <div className={'col-md-3'}>
              <Buttons isVisible={true} isSingle={true} name={'img360'}
                       onChange={(event) => this.handleUpload(event)}
                       icon={faVrCardboard} label={'Importer une image 360°'} accept={"image/*"}/>
            </div>
          </div>
          <div className={"form-group row my-5"}>
            <label htmlFor={"gallery"} className={"align-btn-group col-2"}>Contenu audio&nbsp;:</label>
            <div className={"col-md-7 text-center"}>
              {poi.course && poi.course[this.state.currentCourse._id] && poi.course[this.state.currentCourse._id].audio ?
                <audio controls>
                  <source
                    src={(/https?:\/\//.test(poi.course[this.state.currentCourse._id].audio) ? '' : API_URL) + poi.course[this.state.currentCourse._id].audio}
                    type="audio/mp3"/>
                  Your browser does not support the <code>audio</code> tag.
                </audio>
                :
                null
              }
            </div>
            <div className={'col-md-3'} style={{}}>
              <Buttons isVisible={true} isSingle={true} name={'audio'}
                       onChange={(event) => this.handleUpload(event)}
                       icon={faVolumeUp}
                       label={(poi.course && poi.course[this.state.currentCourse._id] && poi.course[this.state.currentCourse._id].audio ? 'Remplacer la ' : 'Importer une') + ' piste audio'}
                       accept={"audio/*"}/>
            </div>
          </div>
          <div className={"row"}>
            <label htmlFor={"poiDesc"} className={"col-2 col-form-label"}>
              Description&nbsp;:
            </label>
            <div className={"col-sm-10"} style={{minHeight: '300px'}}>
              <Editor
                apiKey={EDITOR_KEY}
                value={poi.course && poi.course[this.state.currentCourse._id] && poi.course[this.state.currentCourse._id].description ? poi.course[this.state.currentCourse._id].description : poi.description}
                init={{
                  height: 300,
                  menubar: true,
                  plugins: [
                    'advlist autolink lists link image',
                    'charmap print preview anchor help',
                    'searchreplace visualblocks code',
                    'insertdatetime media table paste wordcount'
                  ],
                  toolbar:
                    `undo redo | formatselect | bold italic |
                                        alignleft aligncenter alignright |
                                        bullist numlist outdent indent | help`,
                  entity_encoding: "raw",
                }}
                onEditorChange={this.handleEditorChange}
              />
            </div>
          </div>
          <div className={'d-flex justify-content-end mt-3'}>
            <button id={'savePoiBtn'} type={"button"} className={"btn btn-save"}
                    onClick={() => this.handleSavePoi()}>Enregister
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default EditPOI
