import React from "react";
import Fetch from "../util/Fetch";
import '../stylesheets/Courses.css'
import {fas} from '@fortawesome/free-solid-svg-icons'
import CoursesTableList from "./TableList/CoursesTableList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API_URL} from "../bin/config" ;

class Courses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: {picto: 'Icône', title: 'Titre', description: 'Description'},
            course: {},
            coursesList: [],
            listIcon: Object.values(fas),
            isShowing: false,
            searchFilterV: '',
            selectedDegreeRender: null,
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleCourseIcon = this.handleCourseIcon.bind(this);
        this.handleSaveCourseChanges = this.handleSaveCourseChanges.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleChangeCourseTitle = this.handleChangeCourseTitle.bind(this);
        this.handleSearchFilterChange = this.handleSearchFilterChange.bind(this);
        this.onChangeDegree = this.onChangeDegree.bind(this);
        this.onChangeFaceBook = this.onChangeFaceBook.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this)
    }

    componentDidMount() {
        this.getAllCourses()
    }

    handleClick(id) {
        this.state.coursesList.map((el, i) =>
            el._id === id ?
                this.setState({
                    course: {...el}
                })
                :
                el
        )
    }

    async onChangeDegree(event) {
        if (!event.target.files[0]) {
            return
        }
        const file = event.target.files[0];
        const formData = new FormData();
        //Create image object to send on server -
        formData.append('file', file);
        const upload = await Fetch.create('/api/upload/pdf', formData, true);
        if (upload.result) {
            this.setState({
                course: {
                    ...this.state.course,
                    "degree": upload.response.file,
                }
            })
        }
    }

    async onChangeFaceBook(event) {
        if (!event.target.files[0]) {
            return
        }
        const file = event.target.files[0];
        const formData = new FormData();
        //Create image object to send on server -
        formData.append('image', file);
        const upload = await Fetch.create('/api/upload/image', formData, true);
        if (upload.result) {
            this.setState({
                course: {
                    ...this.state.course,
                    facebook: upload.response.imageUrl,
                }
            })
        }
    }

    onChangeInput(event) {
        this.setState({
            course: {
                ...this.state.course,
                [event.target.id]: event.target.value,
            }
        })
    }

    async getAllCourses() {
        const getCourses = await Fetch.get('/api/course');
        if (getCourses.result) {
            this.setState({
                coursesList: getCourses.response.courses
            })
        } else {
            // console.log(getCourses)
        }
    }

    handleCourseIcon(title) {
        // console.log(title);
        this.setState({
            searchFilterV: "",
            course: {
                ...this.state.course,
                picto: title,
            },
            isShowing: false
        })
    }

    handleChangeCourseTitle(event) {
        this.setState({
            course: {
                ...this.state.course,
                title: event.target.value
            }
        })
    }

    handleEditorChange(event) {
        this.setState({
            course: {
                ...this.state.course,
                description: event.target.value
            }
        })
    }

    handleSearchFilterChange(event) {
        this.setState({
            searchFilterV: event.target.value
        })
    }

    async handleSaveCourseChanges() {
        await Fetch.update('/api/course/' + this.state.course._id, this.state.course);
        this.setState({
                course: ''
            }, () => this.getAllCourses()
        )
    }

    render() {
        return (
            <div className={'row frame'}>
                <div
                    className={Object.keys(this.state.course).length > 0 ?
                        'col-lg-6 justify-content-center separator' : 'col-lg-12 justify-content-center '}>
                    <CoursesTableList title={'Liste des parcours'} columns={this.state.columns}
                                      coursesList={this.state.coursesList} onClick={this.handleClick}/>
                </div>
                <div className={'col-lg-6 justify-content-center'}>
                    {Object.keys(this.state.course).length > 0 ?
                        <form>
                            <div className={'d-flex justify-content-start pb-3'}>
                                <button className={'btn'} onClick={() => this.setState({course: ''})}><FontAwesomeIcon
                                    icon={'chevron-left'}/> Retour
                                </button>
                            </div>
                            <div className="form-group row pt-2">
                                <label htmlFor="dropdownMenuButton" className="col-sm-3 col-form-label">
                                    Icône du parcours :
                                </label>
                                <div className={'col-sm-3 d-flex justify-content-center align-items-center'}>
                   <span style={{fontSize: "1.5em", color: "rgb(246, 141, 46)"}}>
                       <FontAwesomeIcon icon={this.state.course.picto}/>
                       <span style={{
                           marginLeft: "5px",
                           fontSize: ".8em",
                           color: "rgb(246, 141, 46)"
                       }}>{this.state.course.picto}</span>
                   </span>

                                </div>
                                <div className="dropdown col-sm-6">
                                    <input type="text" className={'custom-select-input-search-filter form-control'}
                                           placeholder="Rechercher un icone par nom"
                                           value={this.state.searchFilterV || ''}
                                           id="custom-dropdown-filter"
                                           onChange={(event) => this.handleSearchFilterChange(event)}
                                           onFocus={() => this.setState({isShowing: true})}
                                           onBlur={() => setTimeout(() => this.setState({isShowing: false}), 200)}
                                           autoComplete={"off"}
                                    />
                                    <div id={"custom-dropdown"} className="row custom-select-menu"
                                         aria-labelledby="dropdownMenuButton"
                                         style={{display: this.state.isShowing ? 'flex' : 'none'}}>


                                        {this.state.listIcon
                                            .filter(icon => icon.iconName.includes(this.state.searchFilterV))
                                            .map((icon, i) => {
                                                if (icon && icon.iconName === 'font-awesome-logo-full') {
                                                    return null
                                                }
                                                return (
                                                    <div key={i} id={icon.iconName}
                                                         className="custom-select-menu-span-item"
                                                         title={icon.iconName}
                                                         onClick={(event) => this.handleCourseIcon(icon.iconName)}>
                                                        <FontAwesomeIcon icon={[icon.prefix, icon.iconName]}
                                                                         size={'2x'}/>
                                                        <span id={icon.iconName}
                                                              className={'custom-select-menu-span-item-content'}>{icon.iconName}</span>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput1">Titre :</label>
                                <input type="text" className="form-control" id="exampleFormControlInput1"
                                       onChange={this.handleChangeCourseTitle}
                                       value={this.state.course.title || ''}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Description :</label>
                                <textarea type="text" className="form-control" id="exampleFormControlTextarea1"
                                          rows={4} onChange={this.handleEditorChange}
                                          value={this.state.course.description || ''}/>
                            </div>
                            <div className={'row'}>
                                <div className="form-group col-6">
                                    <label htmlFor="degree">Diplôme :</label>
                                    <label className={"file-image-label"}>
                                        <img
                                            src={API_URL + (this.state.course.degree ? this.state.course.degree.replace(".pdf", ".png") : '/uploads/defaultDegree.png')}
                                            alt={"default degree"}/>
                                        <input id={"degree"} type={"file"} onChange={this.onChangeDegree}
                                               accept="application/pdf"/>
                                        <span id={"degree_name_render"} style={{
                                            top: this.state.course.degree_name_top + '%',
                                            left: this.state.course.degree_name_left + '%',
                                            backgroundColor: this.state.selectedDegreeRender == "name" ? "#AF2B65" : "rgb(246, 141, 46)"
                                        }}/>
                                        <span id={"degree_date_render"} style={{
                                            top: this.state.course.degree_date_top + '%',
                                            left: this.state.course.degree_date_left + '%',
                                            backgroundColor: this.state.selectedDegreeRender == "date" ? "#AF2B65" : "rgb(246, 141, 46)"
                                        }}/>
                                    </label>
                                </div>
                                <div className="form-group col-6">
                                    <fieldset>
                                        <legend>Position du nom du Candidat</legend>
                                        <label htmlFor="degree_name_top" className={"col-8"}>Position verticale</label>
                                        <input type={"number"} className={"col-3"} name={"degree_name_top"}
                                               id={"degree_name_top"}
                                               value={this.state.course.degree_name_top || 0}
                                               onChange={this.onChangeInput}
                                               onFocus={() => this.setState({selectedDegreeRender: 'name'})}
                                               onBlur={() => this.setState({selectedDegreeRender: null})}/>&nbsp;%
                                        <label htmlFor="degree_name_left" className={"col-8"}>Position
                                            horizontale</label>
                                        <input type={"number"} className={"col-3"} name={"degree_name_left"}
                                               id={"degree_name_left"}
                                               value={this.state.course.degree_name_left || 0}
                                               onChange={this.onChangeInput}
                                               onFocus={() => this.setState({selectedDegreeRender: 'name'})}
                                               onBlur={() => this.setState({selectedDegreeRender: null})}/>&nbsp;%
                                    </fieldset>
                                    <fieldset>
                                        <legend>Position de la Date</legend>
                                        <label htmlFor="degree_date_top" className={"col-8"}>Position verticale</label>
                                        <input type={"number"} className={"col-3"} name={"degree_date_top"}
                                               id={"degree_date_top"}
                                               value={this.state.course.degree_date_top || 0}
                                               onChange={this.onChangeInput}
                                               onFocus={() => this.setState({selectedDegreeRender: 'date'})}
                                               onBlur={() => this.setState({selectedDegreeRender: null})}/>&nbsp;%
                                        <label htmlFor="degree_date_left" className={"col-8"}>Position
                                            horizontale</label>
                                        <input type={"number"} className={"col-3"} name={"degree_date_left"}
                                               id={"degree_date_left"}
                                               value={this.state.course.degree_date_left || 0}
                                               onChange={this.onChangeInput}
                                               onFocus={() => this.setState({selectedDegreeRender: 'date'})}
                                               onBlur={() => this.setState({selectedDegreeRender: null})}/>&nbsp;%
                                    </fieldset>
                                </div>
                            </div>
                            <hr/>
                            <div className={'row'}>
                                <div className="form-group col-6">
                                    <label htmlFor="facebook">Image Facebook :</label>
                                    <label className={"file-image-label"}>
                                        <img src={API_URL + (this.state.course.facebook || '/uploads/defaultFB.png')}
                                             alt={"facebook"}/>
                                        <input id={"facebook"} type={"file"} onChange={this.onChangeFaceBook}
                                               accept="image/png"/>
                                    </label>
                                </div>
                                <div className="form-group col-6">
                                    <fieldset>
                                        <legend>
                                            <label htmlFor="facebook_text">Message Facebook :</label>
                                        </legend>
                                        <textarea id={"facebook_text"} name={"facebook_text"}
                                                  onChange={this.onChangeInput}
                                                  value={this.state.course.facebook_text} rows={6}
                                                  style={{width: '100%', borderColor: 'silver'}}/>
                                    </fieldset>
                                </div>
                            </div>
                            <div className={'d-flex justify-content-end'}>
                                <button type={'button'} className={'btn btn-save'}
                                        onClick={this.handleSaveCourseChanges}>
                                    Enregistrer
                                </button>
                            </div>
                        </form>
                        :
                        null
                    }
                </div>
            </div>
        )
    }
}

export default Courses
