import React, {useEffect, useState} from "react";
import '../../stylesheets/EditGame.css'
import QuizDataTableList from "../TableList/QuizDataTableList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import Fetch from "../../util/Fetch";

const columns = {title: 'Questions', countOptions: 'Nb options'};

const QuizGame = (props) => {

    const [game, setGame] = useState(props.quiz);
    const [newQuestion, setNewQuestion] = useState('');
    const [newOption, setNewOption] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState(-1);
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        Fetch.get('/api/course').then(result => {
            if (!result.response.courses) {
                result.response.courses = {elements: []};
            }
            setCourses(result.response.courses);
        })
    }, []);

    const addQuestion = question => {
        let currentGame = {...game};
        if (!currentGame.datas || !currentGame.datas.push) {
            currentGame.datas = [];
        }
        currentGame.datas.push({
            title: question,
            options: [],
            answer: ""
        });
        setGame(currentGame);
        setNewQuestion('');
        setSelectedQuestion(currentGame.datas.length - 1);
    };
    const upQuestion = (index) => {
        // console.log('up', index)
        let questions = [] ;
        for(let i in game.datas) {
            i = parseInt(i);
            // console.log(i+1, index)
            if(i !== index) {
                questions.push(game.datas[i])
            }
            if(i-1 === index) {
                questions.push(game.datas[index])
            }
        }
        setGame({...game, datas:questions});
    };
    const downQuestion = (index) => {
        // console.log('down', index)
        let questions = [] ;
        for(let i in game.datas) {
            i = parseInt(i);
            if(i+1 === index) {
                questions.push(game.datas[index])
            }
            if(i !== index) {
                questions.push(game.datas[i])
            }
        }
        setGame({...game, datas:questions});
    };
    const removeQuestion = (index) => {
        let q = [];
        for (let i in game.datas) {
            i = parseInt(i);
            if (i !== index) {
                q.push(game.datas[i])
            }
        }
        setGame({...game, datas: q});
    };

    const addOption = option => {
        let currentGame = {...game};
        if (!currentGame.datas[selectedQuestion].options || !currentGame.datas[selectedQuestion].options.push) {
            currentGame.datas[selectedQuestion].options = [];
        }
        currentGame.datas[selectedQuestion].options.push({
            label: option,
            isTrue: false
        });
        setGame(currentGame);
        setNewOption('');
    };
    const updateOption = (index, isTrue) => {
        game.datas[selectedQuestion].options[index].isTrue = isTrue;
        setGame({...game});
    };

    const removeOption = (index) => {
        let o = [];
        for (var i in game.datas[selectedQuestion].options) {
            i = parseInt(i);
            if (i !== index) {
                o.push(game.datas[selectedQuestion].options[i])
            }
        }
        game.datas[selectedQuestion].options = o;
        setGame({...game});
    };

    return (
        <div className={'row justify-content-center frame'}>
            <div className={'row col-12 pt-3'}>
                <div className={'col-sm-6 justify-content-center separator'}>
                    <div className={'input-group mb-3'}>
                        <label htmlFor={'gameTitle'} className={'col-2'}>Titre du jeu :</label>
                        <input id={'gameTitle'} type={'text'} className={'form-control col-10'} autoComplete={'off'}
                               value={game.title || ''} onChange={(evt) => setGame({...game, title: evt.target.value})}
                               required/>
                    </div>
                    <div className={'input-group mb-3'}>
                        <label htmlFor={'gameDescription'} className={'col-2'}>Description :</label>
                        <textarea id='gameDescription' className={'form-control col-10'} rows={4}
                                  value={game.description || ''}
                                  onChange={(evt) => setGame({...game, description: evt.target.value})} required
                                  autoComplete={'off'}/>
                    </div>
                    <div className={'input-group mb-3'}>
                        <label htmlFor={'gameCourse'} className={'col-2'}>Accessible sur les parcours :</label>
                        <div className={'col-10'}>
                            <div className={'row'}>
                                {courses.map(c => {
                                    return (
                                      <div className={'col-4'} key={c._id}>
                                          <input type={'checkbox'} value={c._id} style={{marginBottom:10}}
                                                 checked={game.courses && game.courses.indexOf(c._id) !== -1}
                                                 onChange={evt => {
                                                     let courses = game.courses || [];
                                                     if (evt.target.checked)
                                                         courses.push(c._id);
                                                     else
                                                         courses = courses.filter(cId => c._id !== cId);
                                                     setGame({...game, courses: [...courses]})
                                                 }}/>
                                          <label style={{marginLeft:10}} onClick={evt => {
                                              let courses = game.courses || [];
                                              if (game.courses && game.courses.indexOf(c._id) !== -1)
                                                  courses = courses.filter(cId => c._id !== cId);
                                              else
                                                  courses.push(c._id);
                                              setGame({...game, courses: [...courses]})
                                          }}>{c.title}</label>
                                      </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <form className={'input-group mb-3'} onSubmit={evt => evt.preventDefault()}>
                        <label htmlFor={'gameQuestion'} className={'col-sm-2'}>
                            <FontAwesomeIcon style={{}} icon={'question-circle'} size={'2x'} color={'grey'}/>
                            <span style={{
                                lineHeight: '2em',
                                verticalAlign: 'super',
                                marginLeft: '1em'
                            }}>Quizz</span></label>
                        <input id={'gameQuestion'} type={'text'} className={'form-control col-sm-12'}
                               value={newQuestion || ''} onChange={(event) => setNewQuestion(event.target.value)}
                               required pattern={'\\w\\w.+'}
                        />
                        <div className={'input-group-append'}>
                            <input className={'btn btn-outline-secondary'} type={'submit'}
                                   disabled={!/\w{2,}.+/gi.test(newQuestion)}
                                   onClick={() => addQuestion(newQuestion.toString())} value={"Ajouter une question"}
                                   autoComplete={'off'}/>
                        </div>
                    </form>
                    {game && game.datas && game.datas.length ? (
                        <QuizDataTableList className={'table-borderless'} title={'Liste des questions'}
                                           columns={columns} game={game} editQuestion={setSelectedQuestion}
                                           removeQuestion={removeQuestion} up={upQuestion} down={downQuestion}/>
                    ) : null}
                </div>
                <div className={'col-sm-6 justify-content-center'} style={{display: 'block', whiteSpace: 'nowrap'}}>
                    {
                        (selectedQuestion !== -1 && game.datas[selectedQuestion]) ? (
                            <>
                                <div className={"input-group mb-3"}>
                                    <label htmlFor={"questionTitle"} className={"col-3 col-form-label"}>
                                        Titre de la question :
                                    </label>
                                    <div className={"col-9"}>
                                        <input type={"text"} className={"form-control"} id={"questionTitle"}
                                               name={'questionTitle'} autoComplete={'off'}
                                               value={game.datas[selectedQuestion].title}
                                               onChange={(event) => {
                                                   game.datas[selectedQuestion].title = event.target.value;
                                                   setGame({...game});
                                               }}/>
                                    </div>
                                </div>
                                <form className={'input-group mb-3'} onSubmit={evt => evt.preventDefault()}>
                                    <label htmlFor={'gameQuestion'} className={'col-3'}>Nouvelle option de
                                        réponse</label>
                                    <div className={"col-9 input-group"}>
                                        <input id={'gameQuestion'} type={'text'} className={'form-control'}
                                               value={newOption || ''}
                                               onChange={(event) => setNewOption(event.target.value)}
                                               required pattern={'\\w\\w.+'} autoComplete={'off'}
                                        />
                                        <div className={'input-group-append'}>
                                            <input className={'btn btn-outline-secondary'} type={'submit'}
                                                   disabled={!/\w{2,}.+/gi.test(newOption)}
                                                   onClick={() => addOption(newOption.toString())}
                                                   value={"Ajouter une option"}/>
                                        </div>
                                    </div>
                                </form>
                                {game.datas[selectedQuestion].options && game.datas[selectedQuestion].options.length ?
                                    game.datas[selectedQuestion].options.map((res, index) => {
                                        return (
                                            <div key={index}
                                                 className={'d-flex justify-content-between col-sm-12 mb-3'}>
                                                <label className="switch">
                                                    <input type={'checkbox'}
                                                     className={'custom-control-input'}
                                                     id={'switchValidity_' + index}
                                                     checked={res.isTrue}
                                                     onChange={(evt) => updateOption(index, !!evt.target.checked)}
                                                    />
                                                    <div className="slider round"><span className="on">Vrai</span><span
                                                        className="off">Faux</span></div>
                                                </label>

                                                <span className={"justify-content-center col-8"}>{res.label}</span>
                                                <span className={'cursor-pointer col-1 text-right'}
                                                      onClick={() => removeOption(index)}
                                                > <FontAwesomeIcon icon={faTrashAlt}/> </span>
                                            </div>
                                        )
                                    }) : null}
                                <div className={"input-group mb-3"}>
                                    <label htmlFor={"questionChoiceDesc"} className={"col-3 col-form-label"}>
                                        Explication :
                                    </label>
                                    <div className={"col-9"}>
                                      <textarea className={'form-control col-sm-12'}
                                                value={game.datas[selectedQuestion].description || ''} autoComplete={'off'}
                                                onChange={evt => {
                                                    game.datas[selectedQuestion].description = evt.target.value;
                                                    setGame({...game});
                                                }}/>
                                    </div>
                                </div>
                            </>
                        ) : null
                    }

                </div>
                <div className={'d-flex col-12 justify-content-end pt-3'}>
                    <button type={'button'} className={'btn btn-save'} onClick={() => props.save(game)}>Enregister
                    </button>
                </div>
            </div>
        </div>
    )
};
export default QuizGame
