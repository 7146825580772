import React, {useEffect, useState} from "react";
import '../stylesheets/ManagePlans.css'
import SwitchRadio from "./SwitchRadio";
import Fetch from "../util/Fetch";
import {API_URL} from '../bin/config';


const ManagePlans = () => {
    const [currentPlan, scp] = useState({});
    const [currentPlanUrl, setCurrentPlanUrl] = useState({});
    
    const [plans, setPlans] = useState({});
    
    useEffect(() => {
        getPlan()
    }, []);
    
    function setCurrentPlan(plan) {
        scp(plan);
        setCurrentPlanUrl(plan.url)
    }
    
    async function getPlan() {
        let plans = await Fetch.get('/api/plans');
        if (plans.result) {
            let resp = [];
            let currentPlan = {};
            for (let i in plans.response) {
                resp.push(plans.response[i]);
                if (!currentPlan._id || currentPlan.level > plans.response[i].level) {
                    currentPlan = plans.response[i]
                }
            }
            setPlans(resp.sort((a, b) => a.level > b.level));
            setCurrentPlan(currentPlan);
        } else {
            // console.log(plans);
        }
    }
    
    async function handleChange(event) {
        let current = plans.find(l => l._id == event.target.value);
        setCurrentPlan(current)
    }
    
    async function handleChangePlan(event) {
        // User cancelled
        if (!event.target.files[0]) {
            return
        }
        const file = event.target.files[0];
        const formData = new FormData();
        //Create image object to send on server -
        formData.append('image', file);
        const upload = await Fetch.create('/api/upload/image', formData, true);
        if (upload.result) {
            for (let i in plans) {
                if (plans[i]._id === currentPlan._id) {
                    plans[i].url = upload.response.imageUrl;
                    await Fetch.update('/api/plans/' + plans[i]._id, plans[i]);
                    // setCurrentPlan(plans[i]);
                }
            }
            setPlans(plans);
            let current = plans.find(l => l._id == currentPlan._id);
            setCurrentPlan(current)
        }
    }
    
    return (
        <div className={'row'}>
            <div className={'d-flex justify-content-center col-12 pb-3'}>
                <SwitchRadio elements={Object.values(plans).sort((a, b) => a.level < b.level ? -1 : 1)}
                             selectedValue={currentPlan._id} handleToggle={handleChange}/>
            </div>
            <div className={'d-flex justify-content-center col-12 pb-3'}>
                <img src={((/https?\:\/\//.test(currentPlanUrl)) ? '' : API_URL) + currentPlanUrl}
                     className={'img-fluid'}
                     alt={'niveau ' + currentPlan.level} title={'niveau ' + currentPlan.level}/>
            </div>
            <div className={"d-flex col-12 justify-content-center"}>
                <div className={'btn'}>
                    <input type={'file'} id={'cfi-react'} onChange={handleChangePlan}/>
                    <label htmlFor={'cfi-react'}>Mettre à jour le plan <b>{currentPlan.shortTitle}</b></label>
                </div>
            </div>
        </div>
    )
};
export default ManagePlans
