import React from "react";
import Fetch from "../util/Fetch";
import "../stylesheets/ToggleSwitch.css"
import '../stylesheets/KonvaComponent.css';
import '../stylesheets/PointOfInterest.css'
import EditPOI from "../components/EditPOI";
import PoiTableList from "./TableList/PoiTableList";
import CanvasTemplate from "../components/Canvas/KonvaComponent"

import {API_URL} from '../bin/config';
import SwitchRadio from "./SwitchRadio";

class PointOfInterest extends React.Component {
  columns = {number: 'N°', title: 'Nom', lastEdit: 'Modifié le', userEdit: 'Par'};

  constructor(props) {
    super(props);
    this.state = {
      plans: [],
      pois: [],
      currentPOI: {},
      currentFloor: {},
      courses: [],

      width: '',
      height: '',
      planRDC: '',
      floor: '',
      plan1st: '',
      list: true,
      poisList: {},
      poi: {},
      isToggled: false,
      poiID: {},
      toggleFloor: "RDC"
    };
    this.newPoi = this.newPoi.bind((this));
    this.savePOI = this.savePOI.bind((this));
    this.updatePOI = this.updatePOI.bind((this));
    this.closePOI = this.closePOI.bind((this));
    this.selectPOI = this.selectPOI.bind((this));
    this.removePOI = this.removePOI.bind((this));
    this.toggleSwitch = this.toggleSwitch.bind((this));
    this.handleCanvasEvent = this.handleCanvasEvent.bind(this);
    this.onCanvasChange = this.onCanvasChange.bind(this);
  }

  componentDidMount() {
    this.getAllPlans();
    this.getAllPois();
    this.getAllCourses();
  }

  async getAllPlans() {
    const getPlans = await Fetch.get('/api/plans');
    let plans = getPlans.response.sort((a, b) => a.level < b.level);
    let currentFloor = plans[0] || {};
    this.setState({plans, currentFloor});

  }

  async getAllCourses() {
    const getCourses = await Fetch.get('/api/course');
    if (getCourses.result) {
      let courses = getCourses.response.courses;
      this.setState({courses})
    }
  }

  async getAllPois() {
    const getPois = await Fetch.get('/api/pois');
    if (getPois.result) {
      this.setState({pois: getPois.response});
    }
  }

  toggleSwitch(event) {
    let currentFloor = this.state.plans.find(l => l._id === event.target.value);
    this.setState({currentFloor, currentPOI: {}})

  }

  closePOI() {
    this.setState({currentPOI: {}});
  }

  savePOI(poi) {
    let req = (poi._id) ? Fetch.update('/api/pois/' + poi._id, poi) : Fetch.create('/api/pois', poi)
    req.then(resp => {
        if (resp.result) {
          this.getAllPois();
          this.setState({currentPOI: {}});
        }
      }
    )
  }

  updatePOI(poi) {
    let up = {currentPOI: poi} ;
    if(poi._id) {
      let {pois} = this.state ;
      let index = pois.findIndex(p =>p._id === poi._id) ;
      pois[index] = poi ;
      up.pois = pois
    }

    this.setState(up)
  }

  selectPOI(poi) {
    this.setState({currentPOI: poi})
  }

  newPoi() {
    this.setState({currentPOI: {floor: this.state.currentFloor.level, x: 10, y: 10}})
  };

  removePOI(poi) {
    // @todo add delete request !!
    let pois = this.state.pois.filter(p => p._id !== poi._id)
    this.setState({currentPOI: {}, pois})
    Fetch.remove('/api/pois/' + poi._id) ;
  };

  async handleCanvasEvent(event) {
    let currentPOIIndex = this.state.pois.findIndex(p => p._id === event.target.attrs.id)
    let currentPOI = this.state.pois[currentPOIIndex];
    this.setState({currentPOI})
  }

  async onCanvasChange(coords, id) {

    let currentPOIIndex = this.state.pois.findIndex(p => p._id === id)
    let currentPOI = {...this.state.pois[currentPOIIndex]};

    if (this.state.currentPOI && !this.state.currentPOI._id) {
      this.setState({currentPOI: {...this.state.currentPOI, ...coords}});
    } else {
      currentPOI = {...currentPOI, ...coords};
      Fetch.update('/api/pois/' + currentPOI._id, currentPOI).then(resp => {
        if (resp.result) {
          let pois = this.state.pois;
          pois[currentPOIIndex] = currentPOI;
          this.setState({currentPOI, pois});
        } else {
          //TODO: Modal error did not update.
          // console.log(resp)
        }
      });
    }
  }

  render() {
    let [width, height] = [(window.innerWidth - 100) / 2, (window.innerHeight - 450)];
    let pois = this.state.pois.filter(a => a.floor === this.state.currentFloor.level);
    if (this.state.currentPOI && this.state.currentPOI.floor && !this.state.currentPOI._id) {
      pois.push(this.state.currentPOI);
    }
    return (
      <div className={"d-flex col-sm-12 mb-5"}>
        <div className={"col-sm-6 justify-content-center "}>
          {this.state.currentPOI && Object.keys(this.state.currentPOI || {}).length ?
            <EditPOI poi={this.state.currentPOI} plans={this.state.plans} currentFloor={this.state.currentFloor}
                     courses={this.state.courses} validate={this.savePOI} update={this.updatePOI}
                     cancel={this.closePOI}/>
            : <PoiTableList title={'Tous les POI'} columns={this.columns}
                            pois={this.state.pois.filter(a => a.floor === this.state.currentFloor.level)}
                            editPOI={this.selectPOI} removePOI={this.removePOI}/>
          }
        </div>
        <div className={"col-sm-6 justify-content-center"}>
          <div className={'d-flex col-sm-12 justify-content-end mb-3'} style={{marginTop: "-20px"}}>
            <button className={"btn-add-poi"} onClick={this.newPoi} name={!this.state.isToggled ? 0 : 1}
                    style={{visibility: this.state.currentPOI && Object.keys(this.state.currentPOI).length ? 'hidden' : 'visible'}}>Créer
              un POI
            </button>
          </div>
          <div className={"d-flex col-sm-12 align-items-center justify-content-center mb-1"}>
            <SwitchRadio elements={this.state.plans.sort((a, b) => a.level < b.level ? -1 : 1)} selectedValue={this.state.currentFloor._id}
                         handleToggle={this.toggleSwitch}/>
          </div>
          <h3 className={"text-center"}>Sélectionnez un POI</h3>
          <CanvasTemplate id={'canvas-zone'}
                          image={(/http:\/\//gi.test(this.state.currentFloor.url) ? '' : API_URL) + this.state.currentFloor.url}
                          pois={pois}
                          onClick={this.handleCanvasEvent}
                          onChange={this.onCanvasChange}
                          onCancel={() => this.setState({currentPOI: {}})}
                          selectedId={this.state.currentPOI._id}
                          width={width}
                          height={height}
                          style={{width: width + 'px', height: height + 'px'}}
          />
        </div>
      </div>
    )
  }
}

export default PointOfInterest
