import {useState} from 'react'

const useAlerts = () => {
    const [isAlert, setIsAlert] = useState(false);
    const [show, setShow] = useState("alert-fade-in");

    function toggleAlert() {
        setIsAlert(!isAlert)
        if (isAlert === false) {
            setShow("alert-fade-in");

        } else if (isAlert === true) {
            setShow('alert-slide-up');
            setTimeout(() => {
                setIsAlert(false);
            }, 1100)
        }
    }

    function toggleAlertContent() {
        setIsAlert(!isAlert)
    }
    
    function alertAuto() {
        setShow("alert-fade-in");
        setIsAlert(true);
        setTimeout(() => {
            dismissAlert()
        }, 2200);
    }
    
    function dismissAlert() {
        setShow('alert-slide-up');
        setTimeout(() => {
            setIsAlert(false);
        }, 4400)
    }

    return {
        isAlert,
        show,
        toggleAlert,
        toggleAlertContent,
        alertAuto
    }
};

export default useAlerts

//TODO: ADD CONDITION FOR ALERT DISMISS
