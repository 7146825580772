import React from "react";
import '../stylesheets/Card.css'
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";
import {faArrowDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Card(props) {
    return (
        <div className={"card card-stats"}>
            <h1 className={"card-header-stats"}>
                {props.title}
            </h1>
            <div className={"card-body text-center stat-color"}>
                {props.stats}
            </div>
            {props.evolution < 0 ? (
                <div className={"pb-2 text-center stat-down"}>
                    <FontAwesomeIcon icon={faArrowDown}/> {Math.round(props.evolution)}% comparé aux 30 J précédents
                </div>
            ) : (
                <div className={"pb-2 text-center stat-up"}>
                    <FontAwesomeIcon icon={faArrowUp}/> +{Math.round(props.evolution)}% comparé aux 30 J précédents
                </div>
            )}
        </div>
    )
}
