import React, {useState} from 'react';
import sha256 from "js-sha256";
import Alert from "../Alerts/Alert";
import Fetch from "../../util/Fetch";
import useForm from "../../model/useForms";
import useAlerts from "../../model/useAlerts";
import validate from "../../model/LoginFormValidationRules";

const LoginForm = (props) => {
    const {values, errors, handleChange, handleSubmit} = useForm(login, validate);
    const {isAlert, show, alertAuto} = useAlerts();
    const [alertClassName, setAlertClassName] = useState(String);
    const [message, setMessage] = useState(String);

    function login() {
        const body = {email: values.email, password: sha256(values.password)};
        const logger = Fetch.create('/users/me', body);
        logger.then(login => {
            if (login.result) {
                props.login(login.data.id);
            } else {
                setAlertClassName('danger');
                setMessage(login.response.msg);
                alertAuto()
            }
        })
    }

    return (
        <div className={'d-flex justify-content-center align-items-center h-100'}>
            <Alert isAlert={isAlert} message={message} className={alertClassName} show={show}/>
            <div className="card" style={{width: '30rem'}}>
                <div className="card-header text-center" style={{borderRadius:'10px 10px 0 0'}}>
                    <img src={'/static/media/logo.e775b193.png'} style={{width:'50%'}} alt={"logo du musée"}/>
                </div>
                <div className={"card-body d-flex justify-content-center"}>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label className="label">Email</label>
                            <input className={errors.email ? "form-control border border-danger" : "form-control"}
                                   autoComplete="off"
                                   type="email"
                                   name="email"
                                   onChange={handleChange}
                                   value={values.email || ''}
                                   required/>
                            <div className={'danger'}>
                                {errors.email && (

                                    <p className={'text-danger'}>{errors.email}</p>
                                )}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="label">Mot de passe</label>
                            <input className={errors.password ? "form-control border border-danger" : "form-control"}
                                   type="password"
                                   name="password"
                                   onChange={handleChange}
                                   value={values.password || ''}
                                   required/>
                            <div className={'danger'}>
                                {errors.password && (
                                    <p className={'text-danger'}>{errors.password}</p>
                                )}
                            </div>
                        </div>
                        <div className={'row justify-content-center'}>
                            <button id={'submit-btn'} type="submit" className="btn">Identification</button>
                        </div>
                    </form>
                </div>
                <div className={'card-footer d-flex justify-content-end'}>Musée Claude Bernard</div>
            </div>
        </div>
    );
};

export default LoginForm;
