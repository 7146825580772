import React from "react";
import '../../stylesheets/TableList.css'
import {faEdit, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Date from "../../util/Date";

class PoiTableList extends React.Component {

    render() {
        return (
            <table className={"table mt-5"}>
                <thead className={'thead-light'}>
                {this.props.pois.length > 0 ?
                    <tr>
                        {Object.keys(this.props.columns).map(column =>
                            <th key={column} className={(column === 'title' ? '' : 'text-center') +' '+ column}>{this.props.columns[column]}</th>
                        )}
                        <th className="text-center actions">Actions</th>
                    </tr>
                    :
                    null
                }
                </thead>
                <tbody>
                {this.props.pois.length > 0 ?
                    this.props.pois.map(poi => {
                        return (
                            <tr key={poi._id}>
                                {Object.keys(this.props.columns).map(column =>
                                    <td key={column} className={(column === 'title' ? '' : 'text-center')}>
                                        {column === 'lastEdit' ? Date.getDateFormatted(poi[column]) : poi[column]}
                                    </td>
                                )}
                                <td className={'text-center actions'}>
                                    <button
                                        type="button"
                                        id="editItemBtn"
                                        onClick={() => this.props.editPOI(poi)}
                                        className="table-action-btn"
                                    >
                                        <FontAwesomeIcon icon={faEdit}/>
                                    </button>
                                    <button
                                        type="button"
                                        id="deleteItemBtn"
                                        onClick={() => {if(window.confirm('Attention, cette suppression est irréversible')) this.props.removePOI(poi)}}
                                        className="table-action-btn"
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                    </button>
                                </td>
                            </tr>
                        )
                    }) : null}
                </tbody>
            </table>
        )
    }
}

export default PoiTableList
