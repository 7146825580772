//let _apiHost = "https://api.service.com/v1";
let _apiHost = require('../bin/config').API_URL;

async function request(url, params, method = "GET", formData = false) {
    let options = {
        method,
        headers: {
            "Content-Type": "application/json"
        },
        credentials: 'include'
    };
    if (params) {
        if (method === "GET") {
            url += "?" + objectToQueryString(params);
        } else if (formData) {
            delete options.headers;
            options.body = params
        } else {
            options.body = JSON.stringify(params);
        }
    }
    //let response = await fetch(_apiHost + url, options);
    let response = await fetch(_apiHost + url, options);
    if (response.status !== 200) {
        return generateErrorResponse(
            "The server responded with an unexpected status."
        );
    }

    let result = await response.json();
    return result;
}

function objectToQueryString(obj) {
    return Object.keys(obj)
        .map(key => key + "=" + obj[key])
        .join("&");
}

function generateErrorResponse(message) {
    return {
        status: "error",
        message
    };
}

function get(url, params) {
    return request(url, params);
}

function create(url, params, formData = false) {
    return request(url, params, "POST", formData);
}

function update(url, params) {
    return request(url, params, "PUT");
}

function remove(url, params) {
    return request(url, params, "DELETE");
}

function upload(url, params, formData = true) {
    return request(url, params, "POST", formData)
}



export default {
    get,
    create,
    update,
    remove,
    upload
};
