import React, {useState} from "react";
import '../../stylesheets/EditGame.css'
const NewGame = (props) => {
  const [game, setGame] = useState({});

  return (
    <div className={'col-12 justify-content-center'}>
      <div className={'col-12 d-flex justify-content-center'}>
        <div className="form-group col-6">
          <label htmlFor="gameTitle">Titre</label>
          <input type="text"
                 className="form-control"
                 id="gameTitle"
                 aria-describedby="gameTitle"
                 value={game.title || ''}
                 onChange={event => setGame({...game, title: event.target.value})}
          />
        </div>
      </div>
      <div className={'col-12 d-flex justify-content-center'}>
        <div className="form-group col-6">
          <label htmlFor="exampleInputPassword1">Description</label>
          <textarea
            className="form-control"
            value={game.description || ''}
            rows={4}
            onChange={event => setGame({...game, description: event.target.value})}
          />
        </div>
      </div>
      <label className={'col-12 text-center'}>Catégorie du jeu</label>
      <div className={'col-12 d-flex justify-content-around'}>
        <select className="custom-select" onChange={event => setGame({...game, type: event.target.value})}>
          <option defaultValue>Sélectionnez une catégorie de jeu</option>
          <option value="quiz">Quiz</option>
          <option value="puzzle">Puzzle</option>
        </select>
      </div>
      <div className={'col-12 d-flex justify-content-center pt-3'}>
        <button type={'button'} className={'btn'} onClick={() => props.saveGame(game)}>Suivant</button>
      </div>
    </div>

  )
};

export default NewGame ;
