import React from "react";
import '../../stylesheets/TableList.css'
import Images from "../UploadFiles/Images";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API_URL} from "../../bin/config";


class PuzzleElementsTableList extends React.Component {
  state = {
    image: null,
    value: null,
  };

  componentDidMount() {
    this.loadValue();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.value !== this.props.value) {
      this.loadValue();
    }
  }

  loadValue() {
    // save to "this" to remove "load" handler on unmount
    this.value = this.props.value
  }

  render() {
    return (
      <table className={"table puzzleTable"}>
        <thead className={'thead-light'}>
        {this.props.itemList.length > 0 ?
          <tr>
            {Object.values(this.props.columns).map((item, i) =>
              <th key={i} className={'text-center'}>{item}</th>
            )}
            <th className="text-center">Actions</th>
          </tr>
          :
          null
        }
        </thead>
        <tbody>
        {this.props.itemList.length > 0 && this.props.isEditable ? this.props.itemList.map(item => {
            let index = this.props.itemList.findIndex(i => i === item);
            return (
              <tr key={item._id ? item._id : index} className={'mb-2'}>
                {Object.keys(this.props.columns).map(column =>
                  <td key={column} className={'text-center'}>
                    {column === 'source' ?
                      <img src={(/https?:\/\//.test(item[column]) ? '' : API_URL) + item[column]} width={100}
                           height={100} className={'shadow'} style={{objectFit: 'contain'}}/>
                      :
                      <>
                        <input type={column === "title" ? "text" : "number"} className={"text-center"}
                               min={"1"} max={"99"}
                               value={item[column] ? item[column] : ''} name={column}
                               onChange={(event) =>
                                 this.props.onChange(event, item._id ? item._id : index)}
                               style={{}}/>
                               &nbsp; %
                      </>
                    }
                  </td>
                )}
                <td className="text-center">
                  <button
                    type="button"
                    value={item._id ? item._id : index}
                    id="deleteItemBtn"
                    onClick={() => this.props.onClick(item._id ? item._id : index)}
                    className="table-action-btn"
                  >
                    <FontAwesomeIcon icon={faTrashAlt}/>
                  </button>
                </td>
              </tr>
            )
          })
          : null
        }
        </tbody>
      </table>
    )
  }
}

export default PuzzleElementsTableList
