import React from "react";
import '../../stylesheets/TableList.css'
import {faEdit, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Date from "../../util/Date";
import Fetch from "../../util/Fetch";

class GameTableList extends React.Component {

  state = {
    players: {}
  };

  componentDidMount() {
    Fetch.get('/api/games/stats').then(r => {
      if (r.result)
        this.setState({players: r.response})
    })
  }

  render() {
    return (
      <div className={"frame"}>
        <table className={"table"}>
          <thead className={'thead-light'}>
          {this.props.gamesList.length > 0 ?
            <tr>
              {Object.keys(this.props.columns).map(column =>
                <th key={'th_' + column}
                    className={column + ' ' + (column !== 'title' ? 'text-center' : '')}>{this.props.columns[column]}</th>
              )}
              <th className="text-center actions">Actions</th>
            </tr>
            :
            null
          }
          </thead>
          <tbody>
          {this.props.gamesList.length > 0 ?
            this.props.gamesList.map(game => {
              let index = this.props.gamesList.findIndex(i => i === game);
              return (
                <tr key={index}>
                  {Object.keys(this.props.columns).map(column => {
                      return (
                        <td key={column} className={column !== 'title' ? 'text-center' : ''}>
                          {column === 'type' ? (
                            <FontAwesomeIcon icon={game[column] === 'puzzle' ? "puzzle-piece" : "question-circle"}
                                             size={"2x"} color={'grey'}/>
                          ) : (
                            column === 'lastEdit' ?
                              Date.getDateFormatted(game[column]) : (
                                column === 'userCount' ? (
                                    this.state.players[game._id] ?
                                      this.state.players[game._id] :
                                      "--"
                                  ) :
                                  game[column]
                              )
                          )}
                        </td>
                      )
                    }
                  )}
                  <td className={'text-center actions'}>
                    <button
                      type="button"
                      value={index}
                      id="editItemBtn"
                      onClick={() => this.props.open(game)}
                      className="table-action-btn"
                    >
                      <FontAwesomeIcon icon={faEdit}/>
                    </button>
                    <button
                      type="button"
                      value={index}
                      id="deleteItemBtn"
                      onClick={() => this.props.remove(game._id)}
                      className="table-action-btn"
                    >
                      <FontAwesomeIcon icon={faTrashAlt}/>
                    </button>
                  </td>
                </tr>
              )
            }) : null}
          </tbody>
        </table>
      </div>
    )
  }
}

export default GameTableList
