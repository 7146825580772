import React from "react";
import '../../stylesheets/TableList.css'
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class QuizDataTableList extends React.Component {

    render() {
        return (
            <table className={"table"}>
                <thead className={'thead-light'}>
                <tr>
                    {Object.keys(this.props.columns).map(column =>
                        <th key={column} className={(column === 'title' ? '' : 'text-center') + ' '+column}>{this.props.columns[column]}</th>
                    )}
                    <th className="text-center actions ">Actions</th>
                </tr>
                </thead>
                <tbody>
                {this.props.game.datas ? this.props.game.datas.map(data => {
                    let index = this.props.game.datas.findIndex(i => i === data);
                    return (
                        <tr key={index}>
                            {Object.keys(this.props.columns).map(column =>
                                <td key={column} className={(column === 'title' ? '' : 'text-center') + ' '+column}>
                                    {column === 'countOptions' ? data.options.length : data[column]}
                                </td>
                            )}
                            <td className={'text-center actions '}>
                                <button
                                    type="button"
                                    value={index}
                                    id="editItemBtn"
                                    onClick={() => this.props.down(index)}
                                    className="table-action-btn"
                                    disabled={index===0}
                                >
                                    <FontAwesomeIcon icon={'arrow-up'}/>
                                </button>
                                <button
                                    type="button"
                                    value={index}
                                    id="editItemBtn"
                                    onClick={() => this.props.up(index)}
                                    className="table-action-btn"
                                    disabled={index===this.props.game.datas.length-1}
                                >
                                    <FontAwesomeIcon icon={'arrow-down'}/>
                                </button>
                                <button
                                    type="button"
                                    value={index}
                                    id="editItemBtn"
                                    onClick={() => this.props.editQuestion(index)}
                                    className="table-action-btn"
                                >
                                    <FontAwesomeIcon icon={faEdit}/>
                                </button>
                                <button
                                    type="button"
                                    value={index}
                                    id="deleteItemBtn"
                                    onClick={() => this.props.removeQuestion(index)}
                                    className="table-action-btn"
                                >
                                    <FontAwesomeIcon icon={faTrashAlt}/>
                                </button>
                            </td>
                        </tr>
                    )
                }) : null}
                </tbody>
            </table>
        )
    }
}

export default QuizDataTableList
