import React from "react";
import sha256 from "js-sha256";
import '../../stylesheets/TableList.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Date from "../../util/Date"
import Fetch from "../../util/Fetch"

class AdminList extends React.Component {
  columns = {lastName: 'Nom', firstName: 'Prénom', email: 'email', date: 'Date de création'}
  state = {
    users: [],
    editing: null,
    openModale: false,
    pass: {
      password: '',
      confirm: '',
    },
    hiddenInput: {
      password: true,
      confirm: true,
    }
  }


  loadUsers() {
    Fetch.get('/users').then(r => {
      if (r.result) {
        this.setState({users: r.response})
      }
    })
  }
  componentDidMount() {
    this.loadUsers()
  }

  _onBlur(evt) {
    evt.persist()
    let [id, prop] = evt.target.name.split('_')
    Fetch.update('/users/' + id, {[prop]: evt.target.value}).then(r => {
      if (r.result) {
        this.setState({editing: null})
      }
    })
  }

  _onFocus(evt) {
    evt.persist()
    this.setState({editing: evt.target.name})
  }

  _onEdit(evt) {
    const id = evt.target.id;
    this.setState({editing: id}, () => {
      document.querySelector('[name="' + id + '"]').focus()
    })
  }

  _onChange(id, prop, value) {
    let users = [...this.state.users]
    for (var i in users) {
      if (users[i]._id === id) {
        users[i][prop] = value;
        this.setState({users});
      }
    }
  }

  addUser() {
    Fetch.create('/users/', {}).then(r => {
      if (r.result) {
        let users = [...this.state.users] ;
        users.push({_id : r.response.id});
        this.setState({users})
      }
    })

  }

  removeUser(user) {
    if(window.confirm('Attention, ceci est irreversible !')) {
      Fetch.remove('/users/' + user._id).then(r => {
        if (r.result) {
          this.loadUsers()
        }
      })
    }
  }

  _changePassword(evt) {
    this.setState({pass: {...this.state.pass, [evt.target.name] : evt.target.value }})
  }

  _updatePassword() {
    if(this.state.pass.password === this.state.pass.confirm &&
      this.state.pass.password.length > 7 &&
      /[0-9]/g.test(this.state.pass.password) &&
      /[A-Z]/g.test(this.state.pass.password) &&
      /[a-z]/g.test(this.state.pass.password)) {
      // update & close
      Fetch.update('/users/' + this.state.openModale, {password: sha256(this.state.pass.password)}).then(r => {
        if (r.result) {
          this.setState({openModale: false})
        }
      })
    }
  }

  render() {
    return (
      <div>
        <div className={'d-flex justify-content-end'}>
          <button className={'btn-add-poi'} onClick={this.addUser.bind(this)}>Nouvel administrateur</button>
        </div>
        <table className={"table"}>
          <thead className={'thead-light'}>
          <tr>
            {Object.keys(this.columns).map(column =>
              <th key={column}
                  className={column + ' ' + (column === 'firstName' || column === 'lastName' ? '' : 'text-center')}>{this.columns[column]}</th>
            )}
            <th className="text-center">Mot de passe</th>
            <th className="text-center">Actions</th>
          </tr>
          </thead>
          <tbody>
          {
            this.state.users.map(user => {
              return (
                <tr key={user._id}>
                  {Object.keys(this.columns).map(column => {
                      let k = user._id + '_' + column;
                      return (
                        <td key={k} className={column === 'firstName' || column === 'lastName' ? '' : 'text-center'}>
                          {
                            column === 'date' ? Date.getDateFormatted(user[column]) : (!user[column] || this.state.editing === k ? (
                              <input type={'text'}
                                     className={"form-control " + (column === 'firstName' || column === 'lastName' ? '' : 'text-center')}
                                     onFocus={this._onFocus.bind(this)} onBlur={this._onBlur.bind(this)}
                                     name={k} onChange={(evt) => {
                                evt.persist()
                                let [i, c] = evt.target.name.split('_');
                                this._onChange(i, c, evt.target.value)
                              }} value={user[column] || ''} onKeyPress={evt => {
                                if (evt.key === "Enter") {
                                  evt.persist()
                                  evt.target.blur()
                                }
                              }}/>
                            ) : <div id={k} onClick={this._onEdit.bind(this)}>{user[column]}</div>)
                          }
                        </td>);
                    }
                  )}
                  <td className="text-center">{/*user.itsMe ?*/
                    <button className={'btn'} onClick={() => this.setState({openModale: user._id})}>Mise à jour du mot de
                      passe</button>/* : ''*/}</td>
                  <td className="text-center"><button className={'table-action-btn'} onClick={() => this.removeUser(user)}><FontAwesomeIcon icon={"trash-alt"} /></button></td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="modal" id="ModalPwd" tabIndex="-1" role="dialog"
             aria-labelledby="ModalPwd" aria-hidden="true"
             style={{display: this.state.openModale ? 'block' : 'none'}}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Changez votre mot de passe</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={() => this.setState({openModale: false})}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <cite style={{fontSize:'.8em', marginBottom:'10px', display:'block'}}>Merci d'utiliser au minimum une majuscule, une minuscule et un chiffre.<br/>
                Votre mot de passe doit contenir au minimum 8 caractères</cite>
                <div className="form-group">
                  <label className="label">Nouveau mot de passe</label>
                  <div className={'input-group'}>
                    <input
                      tabIndex={1}
                      className={"form-control"}
                      type={this.state.hiddenInput.password ? 'password' : 'text'}
                      name="password"
                      onChange={this._changePassword.bind(this)}
                      required/>
                    <div className="input-group-append">
                      <button tabIndex={-1} className="btn btn-outline-secondary" type="button"
                              onClick={() => this.setState(state => ({
                                hiddenInput: {
                                  ...state.hiddenInput,
                                  password: !state.hiddenInput.password
                                }
                              }))}
                      ><FontAwesomeIcon icon={this.state.hiddenInput.password ? 'eye-slash' : 'eye'}/></button>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="label">Confirmez le mot de passe</label>
                  <div className={'input-group'}>
                    <input
                      tabIndex={2}
                      className={"form-control"}
                      type={this.state.hiddenInput.confirm ? 'password' : 'text'}
                      name="confirm"
                      onChange={this._changePassword.bind(this)}
                      required/>
                    <div className="input-group-append">
                      <button tabIndex={-1} className="btn btn-outline-secondary" type="button"
                              onClick={() => this.setState(state => ({
                                hiddenInput: {
                                  ...state.hiddenInput,
                                  confirm: !state.hiddenInput.confirm
                                }
                              }))}
                      ><FontAwesomeIcon icon={this.state.hiddenInput.confirm ? 'eye-slash' : 'eye'}/></button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer" style={{justifyContent: 'space-between'}}>
                <button type="button"
                        className="btn"
                        onClick={() => this.setState({openModale: true})}>
                  Annuler
                </button>
                <button type="button" className="btn btn-save" disabled={!(this.state.pass.password === this.state.pass.confirm && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(this.state.pass.password))} onClick={this._updatePassword.bind(this)}>
                  Enregister
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AdminList
