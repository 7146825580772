import React from 'react';
import {Switch, Route, NavLink, useRouteMatch} from "react-router-dom";
import Statistics from "./Statistics";
import AdminList from "./AdminList";
import ManagePlans from "../ManagePlans";

const MenuUser = () => {
    let {path, url} = useRouteMatch();

    return (
        <div className="row">
            <ul className={"nav nav-tabs col-xl-8 col-lg-10 col-md-12 justify-content-around mt-2 mb-4"}>
                <li className={"nav-item"}>
                    <NavLink to={`${path}`} className={"nav-link"} isActive={(match, location) => {
                        return location.pathname === url;
                    }}>Statistiques</NavLink>
                </li>
                <li className={"nav-item"}>
                    <NavLink to={`${path}/manage-plans`} className={"nav-link"}>Gestion les plans</NavLink>
                </li>
                <li className={"nav-item"}>
                    <NavLink to={`${path}/admin`} className={"nav-link"}>Gestion des utilisateurs</NavLink>
                </li>
            </ul>
            <div className={'col-12 frame'}>
                <Switch>
                    <Route exact path={`${path}`} component={Statistics}/>
                    <Route path={`${path}/manage-plans`} component={ManagePlans}/>
                    <Route path={`${path}/admin`} component={AdminList}/>
                </Switch>
            </div>
        </div>
    );
};

export default MenuUser
