import React from 'react'
import '../../stylesheets/UploadFiles.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

function ButtonSingle(props) {
    return (
        <div className='buttons fadein'>
            <div className={'button '+props.styleClass}>
                <label htmlFor={'single_'+props.name} id={'singleLabel'}>
                    <FontAwesomeIcon icon={props.icon} color='#fffffff' size='2x'/>
                </label>
                <div id={'button-upload-single'}>{props.label}</div>
                <input name={props.name} type='file' id={'single_'+props.name} onChange={props.onChange} accept={props.accept}/>
            </div>
        </div>
    );
}

function MultipleButtons(props) {
    return (
        <div className='buttons fadein'>
            <div className='button'>
                <label htmlFor={'multi_'+props.name} id={'multiLabel_'+props.name}>
                    <FontAwesomeIcon icon={props.icon} color='#6d84b4' size='2x'/>
                </label>
                <input name={props.name} type='file' id={'multi_'+props.name} onChange={props.onChange} accept={props.accept}
                       multiple/>
            </div>
        </div>
    );
}

class Buttons extends React.Component {
    render() {
        const {isSingle, name, onChange, isVisible, icon, label, accept, styleClass} = this.props;
        const content = () => {
            if (!isSingle && isVisible) {
                return <MultipleButtons name={name} isSingle={isSingle} onChange={onChange} icon={icon} accept={accept}/>
            } else if(isSingle && isVisible) {
                return <ButtonSingle name={name} styleClass={styleClass} isSingle={isSingle} onChange={onChange} icon={icon} label={label} accept={accept}/>
            } else {
                return null
            }
        };
        return (content())
    }
}

export default Buttons


