import React from "react";
import {BrowserRouter as Router, Switch, Route, NavLink, withRouter} from "react-router-dom";
import logo from '../logo.png'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faSignOutAlt, faUserCog} from "@fortawesome/free-solid-svg-icons";
import StatisticsCards from './StatisticsCards'
import MenuContents from './MenuContents'
import MenuUser from './Users/MenuUser'

import {API_URL} from '../bin/config'

const Home = (props) => {
    function logout() {
        fetch(API_URL + '/users/me', {method:'DELETE', headers: {"Content-Type": "application/json"},credentials: 'include'}).then(r=>r.json()).then(response => {
            if (response.result) {
                props.logout()
            }
        })
    }

    return (
        <Router>
            <header className={'row'}>
                <div className={'col-12 d-flex justify-content-between'}>
                    <div className={'p-3'}><img src={logo} height={"100px"} width={"200px"} alt={"Logo du musée"}/>
                    </div>
                    <div className={'p-3'} style={{cursor:'pointer'}} onClick={logout}><FontAwesomeIcon icon={faSignOutAlt} size={"3x"}/></div>
                </div>

                <div className={'col-12'}>
                    <ul className={"nav nav-tabs"}>
                        <li className={"nav-item col-md-3 text-center"}>
                            <NavLink exact to={'/home'} className={"nav-link"} activeClassName={'active'}>
                              <h6><FontAwesomeIcon icon={faHome} size={"3x"}/></h6>
                            </NavLink>
                        </li>
                        <li className={"nav-item col-md-6 text-center"}>
                            <NavLink to={'/contents'} className={'nav-link'} activeClassName={'active'}
                                     style={{fontSize: '2rem'}}>
                                <h1>Gestion des contenus</h1>
                            </NavLink>
                        </li>
                        <li className={"nav-item col-md-3 text-center"}>
                            <NavLink to={'/user'} className={"nav-link"} activeClassName={'active'}>
                                <h6><FontAwesomeIcon icon={faUserCog} size={"3x"}/></h6>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </header>
            <Switch>
                <Route exact path={'/home'} component={StatisticsCards}/>
                <Route path={'/contents'} component={MenuContents}/>
                <Route path={'/user'} component={MenuUser}/>
            </Switch>
        </Router>
    )
};


export default withRouter(Home)
