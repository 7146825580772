import React, {Component, Fragment} from 'react';
import {Stage, Layer, Image, Circle, Text} from 'react-konva';

class URLImage extends React.Component {
    state = {
        image: null
    };

    componentDidMount() {
        this.loadImage();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.src !== this.props.src) {
            this.loadImage();
        }
    }

    componentWillUnmount() {
        this.image.removeEventListener('load', this.handleLoad);
    }

    loadImage() {
        // save to "this" to remove "load" handler on unmount
        this.image = new window.Image();
        this.image.src = this.props.src;
        this.image.addEventListener('load', this.handleLoad);
    }

    handleLoad = () => {
        // after setState react-konva will update canvas and redraw the layer
        // because "image" property is changed
        this.setState({
            image: this.image
        });
        this.props.onLoad(this.image.width, this.image.height)
        // if you keep same image object during source updates
        // you will have to update layer manually:
        // this.imageNode.getLayer().batchDraw();
    };

    render() {

        return (
            <Image
              {...this.props}
                image={this.state.image}
                ref={node => {
                    this.imageNode = node;
                }}
            />
        );
    }
}


class CanvasTemplate extends Component {
    state = {
        width : '',
        height : '',
    };
    onLoad(width, height) {
        this.setState({width, height})
    }
    render() {
        let W, H, scale = 1;
        if(this.props.width > this.state.width) {
            W = this.state.width ;
            H = this.state.height ;
        } else {
            W = this.props.width ;
            H = this.state.height / this.state.width * this.props.width ;
        }
        if(H > this.props.height) {
            H = this.props.height;
            W = this.state.width / this.state.height * this.props.height ;
        }
        scale = H / this.state.height ;

        return (
            <Stage id={'konva-stage'} className={'d-flex align-center'} style={this.props.style ||{}} width={W} height={H} scale={{x:scale, y:scale}}>
                <Layer id={'konva-layer'}>
                    <URLImage src={this.props.image} className={'shadow'} onLoad={this.onLoad.bind(this)} onClick={this.props.onCancel} />
                    {this.props.pois && this.props.pois.length > 0 ? this.props.pois.map(poi => {
                        let x = parseFloat(poi.x), y = parseFloat(poi.y);
                        // console.log(x, y)
                        if(x<=0) x = 1 ;
                        if(x>=100) x = 99 ;
                        if(y<=0) y = 1 ;
                        if(y>=100) y = 99 ;
                        // console.log(x, y)
                        return (
                          <Fragment key={poi._id}>
                            <Circle
                                className={'shadow'}
                                radius={8}
                                id={poi._id}
                                fill={this.props.selectedId === poi._id ? "#AF2B65" : "rgb(246, 141, 46)"}
                                stroke={this.props.selectedId === poi._id ? "#AF2B65" : "rgb(246, 141, 46)"}
                                draggable={true}
                                onClick={this.props.onClick}
                                onDragStart={this.props.onClick}
                                onDragEnd={evt => {
                                    let {x, y} = evt.target.attrs, X = Math.round(x), Y = Math.round(y) ;
                                    x = (X/(W/scale)) * 100;
                                    y = (Y/(H/scale)) * 100;
                                    this.props.onChange({x : Math.round(x*10)/10, y: Math.round(y*10)/10}, evt.target.attrs.id);
                                }}
                                y={(y/100) * H / scale}
                                x={(x/100) * W / scale}
                            />
                            <Text text={poi.number} y={((y/100) * H / scale)-5} x={((x/100) * W / scale)-5} id={poi._id}
                                  fill={this.props.selectedId === poi._id ? "white" : "black"} onClick={this.props.onClick}
                            />
                                </Fragment>
                        )
                    }) : null}
                </Layer>
            </Stage>
        );
    }
}

export default CanvasTemplate
