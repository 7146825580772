import React, {Component} from 'react';
import {Image} from 'react-konva';


// custom component that will handle loading image from url
// you may add more logic here to handle "loading" state
// or if loading is failed
// VERY IMPORTANT NOTES:
// at first we will set image state to null
// and then we will set it to native image instance when it is loaded
class ElementImage extends React.Component {
    state = {
        image: null,
        name: ''
    };

    componentDidMount() {
        this.loadImage();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.src !== this.props.src) {
            this.loadImage();
        }
    }

    componentWillUnmount() {
        this.image.removeEventListener('load', this.handleLoad);
    }

    loadImage() {
        // save to "this" to remove "load" handler on unmount
        this.image = new window.Image();
        this.image.onload = function () {
            new window.Image({
                // x: 10,
                // y: 10,
                // width: 100,
                // height: 100,
                draggable: true,
                image: this.image
            });
        };
        this.image.src = this.props.src;
        this.image.addEventListener('load', this.handleLoad);
    }

    handleLoad = () => {
        // after setState react-konva will update canvas and redraw the layer
        // because "image" property is changed
        this.setState({
            image: this.image
        });
        // if you keep same image object during source updates
        // you will have to update layer manually:
        // this.imageNode.getLayer().batchDraw();
    };

    render() {
        return (
            <Image
                id={this.props.id}
                x={this.props.x}
                y={this.props.y}
                width={this.props.width}
                height={this.props.height}
                name={this.props.name}
                image={this.state.image}
                ref={node => {
                    this.imageNode = node;
                }}
                onDragEnd={this.props.onDragEnd}
                onDragMove={e => {
                    e.target.x(Math.round(e.target.x() / 10) * 10);
                    e.target.y(Math.round(e.target.y() / 10) * 10);
                }}
                draggable
            />
        );
    }
}


class PuzzleElement extends Component {
    render() {
        return (
            <ElementImage id={this.props.id} src={this.props.src} name={this.props.name} x={this.props.x}
                          y={this.props.y} height={this.props.height} width={this.props.width} onDragEnd={this.props.onDragEnd}/>
        );
    }
}

export default PuzzleElement
