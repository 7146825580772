import React, {useCallback, useEffect, useState} from 'react';
import {BrowserRouter as Router, useHistory} from 'react-router-dom'
import './stylesheets/App.css';
import LoginForm from "./components/Forms/LoginForm";
import Home from "./components/Home";

function App() {
    const [isConnected, setIsConnected] = useState(false);
    let history = useHistory();
    useEffect(() => {
        let currentSession = sessionStorage.getItem('connected');
        if (currentSession === 'true') {
            setIsConnected(true);
        } else if (currentSession === 'false') {
            // console.log(currentSession);
            setIsConnected(false)
        }
    }, []);


    const login = useCallback((id) => {
            history.push('/home');
            setIsConnected(true);
            sessionStorage.setItem("connected", 'true');
            sessionStorage.setItem("user", id);
        }, [history]
    );

    const logout = useCallback(() => {
            history.push('/');
            setIsConnected(false);
            sessionStorage.removeItem('connected');
            sessionStorage.removeItem('user');
        }, [history]
    );
    return (
        <Router>
            <div className={'container-fluid vh-100'}>
                {isConnected ?
                    <Home logout={logout} login={login}/>
                    :
                    <LoginForm login={() => login()}/>
                }
            </div>
        </Router>
    )
}

export default App
