import React from "react";
import EditPage from "./EditPage";
import Fetch from "../util/Fetch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API_URL} from '../bin/config' ;

class Pages extends React.Component {

  pageTypes = {
    home: {name: "Accueil", type: 'mobile-alt'},
    course: {name: "Page parcours", type: 'mobile-alt', noFeature: true},
    games: {name: "Page des jeux", type: 'mobile-alt', noFeature: true},
    help: {name: "Page d'aide", type: 'mobile-alt'},
    end: {name: "Page de fin", type: 'mobile-alt'},
    'website-home': {type: 'desktop'},
    'website-animations': {type: 'desktop'},
    'website-espace': {type: 'desktop'},
    'website-assoc': {type: 'desktop'},
    'website-contact': {type: 'desktop'},
  }

  constructor(props) {
    super(props);
    this.state = {
      pagesList: [],
      editingPage:{}
    };
    this.backToMenu = this.backToMenu.bind(this)
  }

  componentDidMount() {
    this.getAllPages()
  }

  async getAllPages() {
    const getPages = await Fetch.get('/api/pages');
    if (getPages.result) {
      this.setState({
        pagesList: getPages.response.pages,editingPage:{}
      })
    }
  }

  backToMenu() {
      this.getAllPages()
  }

  render() {
    return (
      <div className={'container-fluid frame'}>
        {
          this.state.toModify ?
            <div class={'mb-3'}>
              <button className={"btn"}
                      type={"button"}
                      onClick={() =>
                        this.setState({
                          toModify: !this.state.toModify
                        }, () => this.getAllPages())
                      }><FontAwesomeIcon icon={'chevron-left'}/> Retour
              </button>
            </div>
            :
            null
        }
        <div className={'frame'} style={{}}>
          {!this.state.editingPage || !this.state.editingPage._id ?
            (<>
              <div className={'d-flex justify-content-around '} style={{height: 'auto'}}>
                {
                  Object.keys(this.pageTypes).map(pageId => {
                    let pageType = this.pageTypes[pageId],
                      page = this.state.pagesList.find(p => p.pageID === pageId) || {};
                    if (pageType.type !== 'mobile-alt') return null;
                    return (
                      <div key={'page_' + pageId} className={'card d-flex  ml-3 mr-3'} style={{flex:'1', maxWidth:"350px"}}>
                        <div className={'card-body p-0'}>
                          <img
                            src={page.featured ? ((/https?:\/\//gi.test(page.featured) ? '' : API_URL) + page.featured) : '/static/default-page.png'}
                            width={"100%"} height={"150px"} className={'card-img-top p-0'}
                            style={{objectFit: 'cover', borderRadius:'10px 10px 0 0', maxWidth:'100%'}}
                            alt={'Image de présentation de la page ' + page.title}/>
                          <div className={'p-3'}>
                            <h5 className={'card-title text-center text-truncate'} style={{height: '1.5em'}}>
                              <FontAwesomeIcon icon={pageType.type}/>&nbsp;{pageType.name}</h5>
                            <p className={'card-text text-center'}
                               style={{height: '50px'}}>{page.title || 'titre non defini'}</p>
                            <div className={'text-center'}>
                              <button className={'btn '} onClick={() => this.setState({editingPage: page}) }>Modifier</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
              <div className={'d-flex justify-content-around mt-4'} style={{height: 'auto'}}>
                {
                  Object.keys(this.pageTypes).map(pageId => {
                    let pageType = this.pageTypes[pageId],
                      page = this.state.pagesList.find(p => p.pageID === pageId) || {};
                    if (pageType.type !== 'desktop') return null;
                    return (
                      <div key={'page_' + pageId} className={'card d-flex ml-3 mr-3'} style={{flex:'1', maxWidth:"350px"}}>
                        <div className={'card-body p-0'}>
                          <img
                            src={page.featured ? ((/https?:\/\//gi.test(page.featured) ? '' : API_URL) + page.featured) : '/static/default-page.png'}
                            width={"100%"} height={"150px"} className={'card-img-top p-0'}
                            style={{objectFit: 'cover', borderRadius:'10px 10px 0 0', maxWidth:'100%'}}
                            alt={'Image de présentation de la page ' + page.title}/>
                          <div className={'p-3'}>
                            <h5 className={'card-title text-center text-truncate'} style={{height: '1.5em'}}>
                              <FontAwesomeIcon icon={pageType.type}/>&nbsp;{page.name}</h5>
                            <p className={'card-text text-center'}
                               style={{height: '50px'}}>{page.title || 'titre non defini'}</p>
                            <div className={'text-center'}>
                              <button className={'btn '} onClick={() => this.setState({editingPage: page}) }>Modifier</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </>) : <EditPage page={this.state.editingPage} structure={this.pageTypes[this.state.editingPage.pageID]} back={this.backToMenu}/>
          }
        </div>
      </div>
    );
  }
}

export default Pages
