import React, {useEffect, useState, Fragment} from "react";
import Fetch from "../../util/Fetch";
import '../../stylesheets/EditGame.css'
import Images from "../UploadFiles/Images";
import Buttons from "../UploadFiles/Buttons";
import CanvasPuzzle from "../Canvas/CanvasPuzzle";
import QuizDataTableList from "../TableList/QuizDataTableList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PuzzleElementsTableList from "../TableList/PuzzleElementsTableList";
import {faCameraRetro, faTrashAlt, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {API_URL} from '../../bin/config' ;
import CanvasTemplate from "../Canvas/KonvaComponent";

const columnsPuzzle = {
  source: 'Image',
  // position: 'Position',
  x: 'x',
  y: 'y'
};
const PuzzleGame = (props) => {
  // Create game properties
  const [gameId, setGameId] = useState(String);
  // PUZZLE properties
  const [isUploaded, setIsUploaded] = useState(false);
  const [puzzleElements, setPuzzleElements] = useState({});
  const [elementIsUploaded, setElementIsUploaded] = useState(false);
  const [columnsSizePuzzle] = useState({position: 40, x: 40, y: 40});
  const [game, setGame] = useState({
    title: '',
    description: '',
    featured: '',
    type: '',
    datas: []
  });
  const [courses, setCourses] = useState([]);
  // Button upload transform to image background for puzzle.
  const Background = () => <Buttons styleClass={'button-puzzle-elements'} name={'background'} isSingle={true}
                                    onChange={handleUpload} isVisible={true} icon={faCameraRetro}
                                    label={'Importer une image'}
  />;

  // Button upload transform to image for puzzle elements.
  const ElementImage = () => <Buttons styleClass={'button-puzzle-elements'} name={'elementImage'} isSingle={true}
                                      onChange={handleUpload} isVisible={true} icon={faCameraRetro}
                                      label={'Importer une image'} accept={'image/png, image/jpeg'}
  />;

  useEffect(() => {
    if (props.puzzle) {
      setGameId(props.puzzleID);
      Fetch.get('/api/games/' + props.puzzleID).then(result => {
        if (!result.response.datas) {
          result.response.datas = {elements: []};
        }
        if (!result.response.datas.elements)
          result.response.datas.elements = [];
        setGame(result.response);
        if (result.response.datas.background !== '') {
          setIsUploaded(true);
        }
      })
      Fetch.get('/api/course').then(result => {
        if (!result.response.courses) {
          result.response.courses = {elements: []};
        }
        setCourses(result.response.courses);
      })
    } else {
      // console.log("error")
    }
  }, [props]);

  //Upload file method calling API
  async function uploadFile(file) {
    const upload = await Fetch.create('/api/upload/image', file, true);
    return upload.response.imageUrl
  }

  const handleUpload = (event) => {
    //IMPORTANT: Let event persist in order to take other file input
    event.persist();
    //PROPERTIES : -
    const formdata = new FormData();
    let file = event.target.files[0];
    let buttonName = event.target.name;
    //Create object image for send to server -
    formdata.append('image', file);
    //Upload file to serve -
    const uploadImage = uploadFile(formdata);
    //Controller -
    if (buttonName === 'background') {
      uploadImage.then(result =>
        setGame({
          ...game,
          datas: {
            ...game.datas, background: result
          }
        }),
      );
      setIsUploaded(true)
    } else if (buttonName === 'elementImage') {
      uploadImage.then(result =>
        setPuzzleElements({
          ...puzzleElements,
          source: result
        }));
      setElementIsUploaded(true)
      ;
    }
  };

  const handleTableListChange = (event, currentIndex) => {
    //PROPERTIES:
    let value = event.target.value;
    let key = event.target.name;
    if (key === 'position' || key === 'x' || key === 'y') {
      value = Number(value)
    }
    setGame(game => ({
      ...game,
      datas: {
        ...game.datas,
        elements: [...game.datas.elements.map((el, index) =>
          index === currentIndex ? {...el, [key]: typeof value === 'number' ? Number(value) : value} : el
        )]
      }
    }))
  };

  const handleRemoveImage = () => {
    setPuzzleElements(puzzleElements => ({
      ...puzzleElements,
      source: ''
    }));
    setElementIsUploaded(false);
  };

  const handleRemoveElement = (index) => {
    let arrayCloned = [...game.datas.elements];
    arrayCloned.splice(index, 1);
    setGame(game => ({
      ...game,
      datas: {
        ...game.datas,
        elements: arrayCloned
      }
    }));
  };

  const AddPuzzleElements = () => {
    if (game.datas && game.datas.elements) {
      let clonedArray = [...game.datas.elements];
      clonedArray.push(puzzleElements);
      setGame(game => ({
        ...game,
        datas: {
          ...game.datas,
          elements: clonedArray
        }
      }));
      setPuzzleElements(puzzleElements => ({
        ...puzzleElements,
        title: '',
        position: 0,
        x: Number(puzzleElements.x + 5),
        y: Number(puzzleElements.y + 5)
      }));
      handleRemoveImage()
    } else {
      setGame(game => ({
        ...game,
        datas: {
          ...game.datas,
          elements: [puzzleElements]
        }
      }));
      setPuzzleElements(puzzleElements => ({
        ...puzzleElements,
        title: '',
        position: 0,
        x: Number(puzzleElements.x + 5),
        y: Number(puzzleElements.y + 5),
      }));
      handleRemoveImage()
    }

  };

  const handleRemoveBackgroundImage = () => {
    setGame(game => ({
      ...game,
      datas: {
        ...game.datas,
        background: ''
      }
    }));
    setIsUploaded(false);
  };

  const onDragEnd = (targetID, x, y) => {
    setGame(game => ({
      ...game,
      datas: {
        ...game.datas,
        elements: [
          ...game.datas.elements.map((el, index) =>
            index === targetID ?
              {
                ...el,
                x: x,
                y: y,
              } :
              el)
        ]
      }
    }))
  };

  async function saveGame() {
    const newGame = await Fetch.update('/api/games/' + gameId, game);
    if (newGame.result) {
      props.backToMenu()
    } else {
      //TODO: modal error
      // console.log(newGame)
    }
  }

  let [width, height] = [(window.innerWidth - 100) / 2, (window.innerHeight - 500)];

  return (
    <div className={'row justify-content-center frame'}>
      <div className={'row col-12 pt-3'}>
        <div className={'col-sm-6 justify-content-center separator'}
             style={{height: 'calc( 100vh - 420px )', overflow: "auto"}}>
          <div className={'input-group mb-3'}>
            <label htmlFor={'gameTitle'} className={'col-2'}>Titre du jeu :</label>
            <input id={'gameTitle'} type={'text'} className={'form-control col-10'} autoComplete={'off'}
                   value={game.title || ''} onChange={(evt) => setGame({...game, title: evt.target.value})}
                   required/>
          </div>
          <div className={'input-group mb-3'}>
            <label htmlFor={'gameDescription'} className={'col-2'}>Description :</label>
            <textarea id='gameDescription' className={'form-control col-10'} rows={4}
                      value={game.description || ''}
                      onChange={(evt) => setGame({...game, description: evt.target.value})} required
                      autoComplete={'off'}/>
          </div>
          <div className={'input-group mb-3'}>
            <label htmlFor={'gameCourse'} className={'col-2'}>Accessible sur les parcours :</label>
            <div className={'col-10'}>
              <div className={'row'}>
                {courses.map(c => {
                  return (
                    <div className={'col-4'} key={c._id}>
                      <input type={'checkbox'} value={c._id} style={{marginBottom:10}}
                             checked={game.courses && game.courses.indexOf(c._id) !== -1}
                             onChange={evt => {
                               let courses = game.courses || [];
                               if (evt.target.checked)
                                 courses.push(c._id);
                               else
                                 courses = courses.filter(cId => c._id !== cId);
                               setGame({...game, courses: [...courses]})
                             }}/>
                      <label style={{marginLeft:10}} onClick={evt => {
                        let courses = game.courses || [];
                        if (game.courses && game.courses.indexOf(c._id) !== -1)
                          courses = courses.filter(cId => c._id !== cId);
                        else
                          courses.push(c._id);
                        setGame({...game, courses: [...courses]})
                      }}>{c.title}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={"input-group mb-5"}>
            <div className={'d-flex col-sm-12'}>
              <label htmlFor={"puzzleBackground"} className={"col-sm-3 col-form-label"}>
                Fond du Puzzle :
              </label>
              {!isUploaded ?
                <div className={"col-sm-9 d-flex justify-content-center align-items-center"}>
                  {Background()}
                </div>
                :
                <div className={"col-sm-9 d-flex justify-content-center align-items-center column"}>
                  <img
                    style={{objectFit: 'contain'}}
                    src={(/https?:\/\//.test(game.datas.background) ? '' : API_URL) + game.datas.background}
                    width={300} height={300}
                    alt={'puzzle-background'}/>
                  <button className={'btn mb-3 btn-link'} type={'button'}
                          id={'removeBackgroundBtn'} onClick={handleRemoveBackgroundImage}>
                    Supprimer cette image
                  </button>
                </div>
              }
            </div>
          </div>
          {isUploaded ?
            <div>
              <div className={'row'}>
                <h3 className={'mb-5'}> {"Element(s) à placer sur le puzzle: "}
                  <label htmlFor={'button-help'} id={'button-help-label'}
                         className={'button-help-label'}>
                    <FontAwesomeIcon icon={faQuestionCircle} className={"button-help-label"}/>
                  </label>
                  <button type={'button'} onClick={() => console.log('clicked !')} className={'button-help'}/>
                </h3>
              </div>
              <div className={"row justify-content-between mb-2"} style={{alignItems: 'center'}}>
                {/*<div className={"col-3"}>*/}
                {/*  <label htmlFor={"elementName"} id={'elementName-label'}*/}
                {/*         className={'input-label'}>*/}
                {/*    Nom de l'élément*/}
                {/*  </label>*/}
                {/*  <input type={"text"} className={"form-control input-elementName"}*/}
                {/*         id={"elementName"}*/}
                {/*         name={'elementTitle'}*/}
                {/*         value={puzzleElements.title || ''}*/}
                {/*         onChange={(event) => setPuzzleElements({*/}
                {/*           ...puzzleElements,*/}
                {/*           title: event.target.value*/}
                {/*         })}/>*/}

                {/*</div>*/}
                <div className={"col text-center"}>
                  {elementIsUploaded ?
                    <>
                      <img
                        src={(/https?:\/\//.test(puzzleElements.source) ? '' : API_URL) + puzzleElements.source}
                        className={'puzzle-element shadow'}
                        alt={'puzzle-element'}
                        width={100}
                        height={100}
                      />
                      <button className={'btn btn-link'}
                              type={'button'}
                              id={'removeElementImageBtn'}
                              onClick={handleRemoveImage}
                      >Supprimer cette image
                      </button>
                    </>
                    :
                    <div>
                      {ElementImage()}
                    </div>
                  }
                </div>
                {/*<div className={"col"}>*/}
                {/*  <label htmlFor={"position"} id={'position-label'}*/}
                {/*         className={'input-label-position'}>*/}
                {/*    Position*/}
                {/*  </label>*/}
                {/*  <input type={"number"} className={"form-control"}*/}
                {/*         id={"position"} name={"position"}*/}
                {/*         min="1"*/}
                {/*         value={puzzleElements.position || ''}*/}
                {/*         onChange={(event) => setPuzzleElements({*/}
                {/*           ...puzzleElements,*/}
                {/*           position: Number(event.target.value)*/}
                {/*         })}/>*/}
                {/*</div>*/}
                <div className={"col"}>
                  <label htmlFor={"poiPositionX"} id={'x-label'}
                         className={'input-label-position'}>
                    X
                  </label>
                  <input type={"number"} className={"form-control"}
                         id={"poiPositionX"} name={"x"}
                         min="10"
                         value={puzzleElements.x || ''}
                         onChange={(event) => setPuzzleElements({
                           ...puzzleElements,
                           x: Number(event.target.value)
                         })}/>
                </div>
                <div className={"col"}>
                  <label htmlFor={"poiPositionY"} id={'y-label'}
                         className={'input-label-position'}>
                    y
                  </label>
                  <input type={'number'} className={"form-control"}
                         id={"poiPositionY"} name={'y'}
                         min="10"
                         value={puzzleElements.y || ''}
                         onChange={(event) => setPuzzleElements({
                           ...puzzleElements,
                           y: Number(event.target.value)
                         })}
                  />
                </div>
                <div className={"col"}>
                  <div className={"col-sm-6 text-center"}>
                    {elementIsUploaded ?
                      <button className={'btn btn-outline-secondary'} type={'button'}
                              id={'addQuestionButton'} onClick={AddPuzzleElements}>
                        Ajouter
                      </button>
                      :
                      null
                    }
                  </div>
                </div>
              </div>
            </div>
            :
            null
          }
          <div className="container">
            <span>Liste des éléments du puzzle</span>
            <div className={'row'}>
              <div className={'col'}>
                <PuzzleElementsTableList isEditable={true}
                                         tableTitle={"Liste des éléments du puzzle :"}
                                         columns={columnsPuzzle}
                                         columnSize={columnsSizePuzzle}
                                         itemList={game.datas.elements || []}
                                         onChange={handleTableListChange}
                                         onClick={handleRemoveElement}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={'col-sm-6 justify-content-center'}>
          <label className={"col-sm-12 col-form-label"}>Puzzle complet :</label>
          {isUploaded ?
            <CanvasPuzzle id={"puzzle-canvas-zone"}
                          image={(/https?:\/\//.test(game.datas.background) ? '' : API_URL) + game.datas.background}
                          puzzleElements={game.datas.elements || []}
                          onDragEnd={onDragEnd}
                          width={width}
                          height={height}
                          style={{width: width + 'px', height: height + 'px'}}
            />
            :
            null
          }
        </div>
        <div className={'d-flex col-12 justify-content-end pt-3'}>
          <button type={'button'} className={'btn btn-save'} onClick={saveGame}>Enregister</button>
        </div>
      </div>
    </div>
  )
};
export default PuzzleGame
