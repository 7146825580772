import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import '../../stylesheets/UploadFiles.css'

function ScaleImage(props) {
    // now we need to fit stage into parent
    const containerWidth = props.width;
    // to do this we need to scale the stage
    const scale = containerWidth / props.width;
    // scale height, width, x, y
    const scaleHeight = props.height * scale;
    const scaleWidth = props.width * scale;
    const scaleXY = {x: scale, y: scale};
    return {scaleHeight, scaleWidth, scaleXY}
}

function ImageSingle(props) {
    const scalerImage = ScaleImage(props);
    return (
        <div className='fadein'>
            {/* <div className='delete'>
                        <FontAwesomeIcon icon={faTimesCircle} size='3x'/>
                    </div>*/}
            <img className={props.className} src={props.image} alt='' width={scalerImage.scaleWidth}
                 height={scalerImage.scaleHeight}/>
        </div>
    );
}

function MultipleImages(props) {
    const scalerImage = ScaleImage(props);
    return (
        <div>
            {props.image.length > 0 ?
                props.image.map((image, i) =>
                    <div key={i} className='fadein'>
                        <div onClick={props.onClick} className='delete'>
                            <FontAwesomeIcon icon={faTimesCircle} size='2x'/>
                        </div>
                        <img className={props.className} src={image} alt={props.alt} width={scalerImage.scaleWidth}
                             height={scalerImage.scaleHeight}/>
                    </div>
                )
                :
                null
            }
        </div>
    );
}


class Images extends React.Component {
    render() {
        const {isSingle, image, className, alt, width, height} = this.props;
        const content = () => {
            if (!isSingle) {
                return <MultipleImages image={image} className={className} alt={alt} width={width} height={height}/>
            } else {
                return <ImageSingle image={image} className={className} alt={alt} width={width} height={height}/>
            }
        };
        return (content())
    }
}

export default Images
