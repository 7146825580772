import React, {useEffect, useState} from 'react';
import Fetch from "../../util/Fetch";
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const colors = ['rgb(0,106,142)',
  'rgb(170,0,97)',
  'rgb(246,141,46)',
  'rgb(0,167,181)',
  'rgb(208,223,0)',
];


const Statistics = () => {
  const [courses, setCourses] = useState([])
  const [stats, setStats] = useState([]);
  const [games, setGames] = useState([]);
  const [allGames, setAllGames] = useState([]);
  const [allPois, setAllPois] = useState([]);
  const [pois, setPois] = useState([]);
  const [opacity, setOpacity] = useState({});


  let _init = () => {
    Fetch.get('/api/course').then(r => {
      if (r.result) {
        setCourses(r.response.courses);
      }
    });
    Fetch.get('/api/pois').then(r => {
      if (r.result) {
        setAllPois(r.response);
      }
    });
    Fetch.get('/api/games').then(r => {
      if (r.result) {
        setAllGames(r.response.games);
      }
    });
    Fetch.get('/api/statWays').then(r => {
      if (r.result)
        setStats(r.response);
    });
    Fetch.get('/api/statPois').then(r => {
      if (r.result)
        setPois(r.response);
    });
    Fetch.get('/api/statGames').then(r => {
      if (r.result)
        setGames(r.response);
    });

  };

  useEffect(_init, []);
  let handleMouseEnter = (o) => {
    setOpacity({...opacity, [o.dataKey]: 0.5})
  }

  let handleMouseLeave = (o) => {
    setOpacity({...opacity, [o.dataKey]: 1})
  }

  if (!courses || !courses.length)
    return <cite>Chargement...</cite>

  const stat = (title, datas, courbes) => {
    return (<fieldset className={"mb-5"}>
      <legend>{title}</legend>
      <div style={{width: '100%', height: 300}}>
        <ResponsiveContainer>
          <LineChart data={Object.values(datas)}>
            <XAxis dataKey="name"/>
            <YAxis/>
            <Tooltip/>
            <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} verticalAlign="top" />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
            {
              courbes.map((c, index) => {
                return (<Line name={c.number ? ('#'+c.number) : c.title} type="monotone" dataKey={c._id} key={c._id} stroke={colors[index]} strokeOpacity={opacity[c._id]}/>)
              })
            }
          </LineChart>
        </ResponsiveContainer>
      </div>
    </fieldset>);
  }

  return (
    <div className={"frame"}>
      {stat('Réalisation des parcours', stats, courses)}
      {stat('Découverte des POIs', pois, allPois)}
      {stat('Réalisation des jeux', games, allGames)}
    </div>
  );
};
export default Statistics;
