export default function validate(values) {
    let errors = {};
    if (!values.email) {
        errors.email = 'Adresse email requise';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Adresse email non valide.';
    }
    if (!values.password) {
        errors.password = 'Mot de passe requis.';
    } else if (values.password.length < 8) {
        errors.password = 'Le mot de passe doit comporter au minimum 8 caractères.';
    }
    return errors;
};