import React from "react";
import ReactDOM from 'react-dom';
import '../../stylesheets/Alerts/Alerts.css'

const Alert = ({isAlert, className, show, message}) => isAlert ? ReactDOM.createPortal(
    <React.Fragment>
        <div className={"alert alert-" + className + " " + show} role="alert">
            {message}
        </div>
    </React.Fragment>, document.body
) : null;

export default Alert