import React, {Component} from "react";
import {Stage, Layer, Transformer, Image, Circle} from "react-konva";
import PuzzleElement from "./PuzzleElement";
import {API_URL} from "../../bin/config";

// custom component that will handle loading image from url
// you may add more logic here to handle "loading" state
// or if loading is failed
// VERY IMPORTANT NOTES:
// at first we will set image state to null
// and then we will set it to native image instance when it is loaded
class Background extends React.Component {
    state = {
        image: null
    };

    componentDidMount() {
        this.loadImage();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.src !== this.props.src) {
            this.loadImage();
        }
    }

    componentWillUnmount() {
        this.image.removeEventListener('load', this.handleLoad);
    }

    loadImage() {
        // save to "this" to remove "load" handler on unmount
        this.image = new window.Image();
        this.image.src = this.props.src;
        this.image.addEventListener('load', this.handleLoad);
    }

    handleLoad = () => {
        // after setState react-konva will update canvas and redraw the layer
        // because "image" property is changed
        this.setState({
            image: this.image
        });
        this.props.onLoad(this.image.width, this.image.height)
        // if you keep same image object during source updates
        // you will have to update layer manually:
        // this.imageNode.getLayer().batchDraw();
    };

    render() {
        return (
            <Image
                {...this.props}
                image={this.state.image}
                ref={node => {
                    this.imageNode = node;
                }}
            />
        );
    }
}

class TransformerComponent extends React.Component {
    componentDidMount() {
        this.checkNode();
    }

    componentDidUpdate() {
        this.checkNode();
    }

    checkNode() {
        // here we need to manually attach or detach Transformer node
        const stage = this.transformer.getStage();
        const {selectedShapeName} = this.props;

        const selectedNode = stage.findOne("." + selectedShapeName);
        // do nothing if selected node is already attached
        if (selectedNode === this.transformer.node()) {
            return;
        }

        if (selectedNode) {
            // attach to another node
            this.transformer.attachTo(selectedNode);
        } else {
            // remove transformer
            this.transformer.detach();
        }
        this.transformer.getLayer().batchDraw();
    }

    render() {
        return (
            <Transformer
                ref={node => {
                    this.transformer = node;
                }}
                boundBoxFunc={(oldBox, newBox) => {
                    newBox.width = Math.round(newBox.width / 10) * 10;
                    newBox.height = Math.round(newBox.height / 10) * 10;
                    return newBox;
                }}
            />
        );
    }
}

class CanvasPuzzle extends Component {
    state = {
        width: '',
        height: '',
        selectedShapeName: '',
    };

    onLoad(width, height) {
        this.setState({width, height})
    }

    handleStageMouseDown = e => {
        // clicked on stage - clear selection
        if (e.target === e.target.getStage()) {
            this.setState({
                selectedShapeName: '',
            });
            return;
        }
        // clicked on transformer - do nothing
        const clickedOnTransformer =
            e.target.getParent().className === "Transformer";
        if (clickedOnTransformer) {
            return;
        }

        // find clicked object by its name
        const name = e.target.name();
        const obj = this.props.puzzleElements.find(r => r.title === name);
        if (obj) {
            this.setState({
                selectedShapeName: name
            });
        } else {
            this.setState({
                selectedShapeName: ""
            });
        }
    };

    onDragEnd(event) {
        let eventTarget = event.target.attrs;
        let ratioI = this.state.width / this.state.height;
        let ratioA = this.props.width / this.props.height;
        // console.log(eventTarget.x, eventTarget.y, this.state.width, this.state.height, ratioI, ratioA);
        let W, H, scale = 1;
        if (ratioI < ratioA) {
            H = this.state.height;
            W = this.state.width;
            scale = this.props.width / W;
            let scaleH = this.props.height / H;
            if (scaleH < scale) {
                scale = scaleH;
            }
        } else {
            H = this.state.height / this.props.height * this.props.width;
            W = this.state.width * ratioA;
        }

        let x = (eventTarget.x / W) * 100;
        let y = (eventTarget.y / H) * 100;

        this.props.onDragEnd(eventTarget.id, x, y)
    };

    render() {
        let W, H, scale = 1;
        if(this.props.width > this.state.width) {
            W = this.state.width ;
            H = this.state.height ;
        } else {
            W = this.props.width ;
            H = this.state.height / this.state.width * this.props.width ;
        }
        if(H > this.props.height) {
            H = this.props.height;
            W = this.state.width / this.state.height * this.props.height ;
        }
        scale = H / this.state.height ;
        return (
            <Stage id={'puzzleCanva-stage'} className={'d-flex align-center'} style={this.props.style || {}} width={W} height={H} scale={{x:scale, y:scale}} onMouseDown={this.handleStageMouseDown}>
                <Layer id={'puzzleCanva-layer'}>
                    <Background src={this.props.image} className={'shadow'} onLoad={this.onLoad.bind(this)} onClick={this.props.onCancel} />
                    {this.props.puzzleElements && this.props.puzzleElements.length > 0 ? this.props.puzzleElements.map((el, i) => {
                        let x = parseFloat(el.x), y = parseFloat(el.y);
                        // console.log(x, y)
                        if(x<=0) x = 1 ;
                        if(x>=100) x = 99 ;
                        if(y<=0) y = 1 ;
                        if(y>=100) y = 99 ;

                        // console.log(W, H)

                        return (
                            <PuzzleElement key={i}
                                           id={i}
                                           src={(/https?:\/\//.test(el.source) ? '' : API_URL) + el.source}
                                           y={(y/100) * H / scale}
                                           x={(x/100) * W / scale}
                                           onDragEnd={evt => {
                                               let {x, y} = evt.target.attrs, X = Math.round(x), Y = Math.round(y) ;
                                               x = (X/(W/scale)) * 100;
                                               y = (Y/(H/scale)) * 100;
                                               this.props.onDragEnd(evt.target.attrs.id, Math.round(x*10)/10, Math.round(y*10)/10, );
                                           }}
                            />
                        )
                    }) : null}
                    <TransformerComponent
                        selectedShapeName={this.state.selectedShapeName}
                    />
                </Layer>
            </Stage>
        );
    }
}

export default CanvasPuzzle
