import React, {useEffect, useState} from "react";
import Card from "./Card";
import Fetch from "../util/Fetch";

const StatisticsCards = () => {
    const [stats, setStats] = useState({
        analytics: [],
        courses: []
    });

    useEffect(() => {
        getStatistics()
    }, []);

    async function getStatistics() {
        // Get pois views count
        const poisViewCount = await Fetch.get('/api/poisViews');
        if (poisViewCount.result) {
            let currentStats = poisViewCount.response.currentStats;
            let oldStats = poisViewCount.response.oldStats;
            setStats(stats => ({
                ...stats,
                analytics: [
                    ...stats.analytics, {
                        title: 'Nb de POI ouverts',
                        value: poisViewCount.response.currentStats,
                        evolution: ((currentStats - oldStats) / oldStats) * 100
                    }
                ]
            }))
        }
        //Get sessions count
        const sessionsCount = await Fetch.get('/api/sessionsCount');
        if (sessionsCount.result) {
            let currentStats = sessionsCount.response.currentStats;
            let oldStats = sessionsCount.response.oldStats || 1;
            setStats(stats => ({
                ...stats,
                analytics: [
                    ...stats.analytics, {
                        title: 'Nb de sessions',
                        value: sessionsCount.response.currentStats,
                        evolution: ((currentStats - oldStats) / oldStats) * 100
                    }
                ]
            }))
        }
        //Get course subscribe count
        const courseSubscribeCount = await Fetch.get('/api/courseStats');
        if (courseSubscribeCount.result) {
            let currentStats = courseSubscribeCount.response.currentStats;
            let oldStats = courseSubscribeCount.response.oldStats;
            setStats(stats => ({
                ...stats,
                courses: [
                    {
                        title: currentStats[0].title,
                        value: currentStats[0].count,
                        evolution: ((currentStats[0].count - oldStats[0].oldCount) / oldStats[0].oldCount) * 100
                    },
                    {
                        title: currentStats[1].title,
                        value: currentStats[1].count,
                        evolution: ((currentStats[1].count - oldStats[1].oldCount) / oldStats[1].oldCount) * 100
                    },
                    {
                        title: currentStats[2].title,
                        value: currentStats[2].count,
                        evolution: ((currentStats[2].count - oldStats[2].oldCount) / oldStats[2].oldCount) * 100
                    },
                ]
            }))
        }
    }

    return (
        <div className={'row'}>
            <div className={'col-12 '}>
                <div className={'row d-flex justify-content-center wrap'}>
                    {
                        stats.analytics.map((stat, index) => {
                            return <Card key={index} title={stat.title} stats={stat.value} desc={""}
                                         evolution={stat.evolution}/>
                        })
                    }
                </div>
                <div className={'row d-flex justify-content-between wrap'}>
                    {
                        stats.courses.map((stat, index) => {
                            return <Card key={index} title={stat.title} stats={stat.value} desc={""}
                                         evolution={stat.evolution}/>
                        })
                    }
                </div>
            </div>
        </div>
    )
};

export default StatisticsCards

