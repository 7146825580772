import React from "react";
import '../../stylesheets/TableList.css'
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class CoursesTableList extends React.Component {

    render() {
        return (
            <table className={"table"}>
                <thead className={'thead-light'}>
                {this.props.coursesList.length > 0 ?
                    <tr>
                        {Object.values(this.props.columns).map((item, i) =>
                            <th key={i} className={'text-center'}>{item}</th>
                        )}
                        <th className="text-center">Actions</th>
                    </tr>
                    :
                    null
                }
                </thead>
                <tbody>
                {this.props.coursesList.length > 0 ?
                    this.props.coursesList.map(course => {
                        let index = this.props.coursesList.findIndex(i => i === course);
                        return (
                            <tr key={course._id ? course._id : index}>
                                {Object.keys(this.props.columns).map(column =>
                                    <td key={column} className={column === 'picto' || column === 'title' ? 'text-center' : ''}>
                                        {column === 'picto' ?
                                            <FontAwesomeIcon icon={course[column] || 'coffee'} size={'3x'}/>
                                            :
                                            course[column].replace(/(<[^>]+>)/ig, '')
                                        }
                                    </td>
                                )}
                                <td className={'text-center'}>
                                    <button
                                        type="button"
                                        value={course._id ? course._id : index}
                                        id="editcourseBtn"
                                        onClick={() => this.props.onClick(course._id ? course._id : index)}
                                        className="table-action-btn"
                                    >
                                        <FontAwesomeIcon icon={faEdit}/>
                                    </button>
                                </td>
                            </tr>
                        )
                    }) : null}
                </tbody>
            </table>
        )
    }
}

export default CoursesTableList
