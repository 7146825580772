import React from "react";
import Fetch from "../../util/Fetch";
import GameTableList from "../TableList/GameTableList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NewGame from "./NewGame";
import PuzzleGame from "./PuzzleGame";
import QuizGame from "./QuizGame";

export default class Games extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentGame:{},
      columns: {type: 'Type', title: 'Nom', userCount: 'Nb. de joueurs', lastEdit: 'Modifié le', userEdit: 'Par'},
      gamesList: []
    };
    this.getAllGames()
  }



  async getAllGames() {
    const getGames = await Fetch.get('/api/games');
    if (getGames.result) {
      this.setState({
        gamesList: getGames.response.games
      })
    }
  }

  _openGame(currentGame) {
    this.setState({currentGame})
  }
  _closeGame() {
    this.setState({currentGame : {}}, this.getAllGames)
  }
  _newGame() {
    this.setState({
      currentGame : {isNew:true}
    })
  }
  _createGame(g) {
    let game = {...g}
    delete game.isNew ;
    Fetch.create('/api/games', game).then(r =>{
      if(r.result) {
        game._id = r.response.insertedId;
        let gamesList = [...this.state.gamesList] ;
        gamesList.push(game) ;
        this.setState({
          gamesList,
          currentGame:game
        })
      }
    })
    this.setState({
      currentGame : {}
    })
  }
  _updateGame(game) {
    Fetch.update('/api/games/'+game._id, game).then(r =>{
      if(r.result) {
        let gamesList = [...this.state.gamesList] ;
        let index = gamesList.findIndex(g => g._id === game._id);
        gamesList[index] = game;
        this.setState({
          gamesList,
          currentGame:{}
        }, this.getAllGames)
      }
    })
  }
  _removeGame(id) {
    if(window.confirm(('Attention ceci est irreversible !'))) {
      Fetch.remove('/api/games/'+id).then(r => {
        if(r.result) {
          this.setState({
            gamesList : this.state.gamesList.filter(g => g._id !== id)
          })
        }
      });
    }
  }


  render() {
    // console.log(Object.keys(this.state.currentGame))
    return (
      <div className={'row '}>
        <div className={'col-12'}>
          {!this.state.currentGame || (!this.state.currentGame.isNew && !this.state.currentGame._id) ? (
            <>
              <div className={'row justify-content-end pr-5'}>
                <button className={"btn-add-poi"} type={"button"} onClick={this._newGame.bind(this)}>Créer un jeu</button>
              </div>
              <GameTableList columns={this.state.columns} gamesList={this.state.gamesList}
                             open={this._openGame.bind(this)} remove={this._removeGame.bind(this)}/>
            </>
          ) : (
            <>
              <div className={'row justify-content-start pl-5'}>
                <button className={"btn mb-2"} type={"button"} onClick={this._closeGame.bind(this)}><FontAwesomeIcon icon={'chevron-left'} /> Retour</button>
              </div>
              {
                this.state.currentGame.isNew ? (
                  <NewGame saveGame={this._createGame.bind(this)} cancel={this._closeGame.bind(this)}/>
                ) : (
                    this.state.currentGame.type === 'puzzle' ? (
                    <PuzzleGame backToMenu={this._closeGame.bind(this)} puzzle={this.state.currentGame}
                        puzzleID={this.state.currentGame._id} isCreating={this.state.isCreating}/>
                  ) : (
                    <QuizGame save={this._updateGame.bind(this)} quiz={this.state.currentGame} />
                    )
                )
              }
            </>
          )}
        </div>
      </div>
    )
  }
}

