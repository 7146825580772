import React from "react";
import '../stylesheets/Switch.scss'

const SwitchRadio = ({ elements, selectedValue, handleToggle, disabled=false, style={} }) => {
  return (
    <div className={"react-switch-radio"} style={style}>
      {
        elements && elements.length ? elements.map(l => {
          return (
            <label
              style={{}}
              className="react-switch-radio-label"
              title={l.title}
              key={l._id}
            >
              <input
                checked={l._id === selectedValue}
                onChange={handleToggle}
                value={l._id}
                className="react-switch-radio-input"
                type="radio"
                disabled={disabled}
              />
              <span className="react-switch-radio-button">{l.title}</span>
            </label>
            )
        }) : null
      }
    </div>
  );
};

export default SwitchRadio;
