function getDateFormatted(MyDate) {
  let MyDateFormatted;
  let dateToConvert = new Date(MyDate);
  let MyDateMonth = dateToConvert.getMonth();
  let MyDateDay = dateToConvert.getDate();
  let MyDateHours = dateToConvert.getHours();
  let MyDateMinutes = dateToConvert.getMinutes();

  if (MyDateMonth < 10) MyDateMonth = "0" + MyDateMonth;
  if (MyDateDay < 10) MyDateDay = "0" + MyDateDay;
  if (MyDateHours < 10) MyDateHours = "0" + MyDateHours;
  if (MyDateMinutes < 10) MyDateMinutes = "0" + MyDateMinutes;

  MyDateFormatted = MyDateDay + "/" + MyDateMonth + "/" + dateToConvert.getFullYear().toString().substr(2) + " " + MyDateHours + ":" + MyDateMinutes

  return (/NaN/gi.test(MyDateFormatted)) ? '' : MyDateFormatted;
}

export default {
  getDateFormatted
}
